// let organisation = localStorage.getItem('organisation') === null ? "sysbrew-test" : localStorage.getItem('organisation');

const host = process.env.NODE_ENV === 'production' ? "http://webservice.sysbrew.in/inventory_manager/" : "http://webservice.sysbrew.in/inventory_manager/";

const Config = {
    token : "NjYzYzgyNzYyNGNmOWQzMmJjNzcxNjU2YTkwMDVmYjhlMDEzNGE3NThkNjExMWJjZWZhZGUwZGU1NDE2OGI1Ng==",
    get_routes: `${host}get_routes.php?`,
    get_detailed_routes: `${host}get_detailed_routes.php?`,
    remove_route: `${host}remove_route.php?`,
    create_route: `${host}create_route.php?`,
    assign_route: `${host}assign_route.php?`,
    assign_store_route: `${host}assign_store_route.php?`,
    get_route_area: `${host}get_route_area.php?`,
    remove_agent_assignment: `${host}remove_agent_assignment.php?`,
    remove_store_assignment: `${host}remove_store_assignment.php?`,
    authenticate_url: `${host}authenticate.php?`,
    get_products_url: `${host}get_products.php?`,
    create_product: `${host}create_product.php?`,
    mark_product_glass: `${host}mark_product_glass.php?`,
    get_agents_url: `${host}get_users.php?type=AGENT&`,
    get_stores_url: `${host}get_users.php?type=STORE&`,
    get_stores_export: `${host}get_stores_export.php?type=STORE&`,
    get_contracts_for_store: `${host}get_contracts_for_store.php?type=STORE&`,
    get_customers_url: `${host}get_users.php?type=CUSTOMER&`,
    create_agent: `${host}create_agent.php?type=AGENT&`,
    create_store: `${host}create_store.php?type=STORE&`,
    remove_agent: `${host}remove_user.php?type=AGENT&`,
    remove_store: `${host}remove_user.php?type=STORE&`,
    update_bottles_returned: `${host}update_bottles_returned.php?`,    
    add_tag: `${host}add_tag.php?`,
    get_user_locations: `${host}get_user_locations.php?`,
    request_user_location: `${host}request_user_location.php?`,
    google_url: "http://maps.google.com?q=",
    get_customer_names: `${host}get_customer_names.php?`,
    export_excel: `${host}export_excel.php?`,
    mark_delivery: `${host}mark_delivery.php?`,
    notify_user_schedules_ready: `${host}notify_user_schedules_ready.php?`,
    get_store_routes: `${host}get_store_routes.php?`,
    get_store_delivery_schedule: `${host}get_store_delivery_schedules.php?`,
    get_store_delivery_schedule_marker: `${host}get_store_delivery_schedule_marker.php?`,
    create_delivery: `${host}create_delivery.php?`,
    remove_delivery: `${host}remove_delivery.php?`,
    resend_invoice: `${host}resend_invoice.php?`,

    // import
    upload_map_url: `${host}upload_map.php?`,
    upload_url: `${host}import_schedule.php?`,
    reassign_routes: `${host}reassign_routes_as_per_mapping.php?`,

    // Reports
    report_summary:`${host}report_summary.php?`,
    report_schedule:`${host}report_schedule.php?`,
    report_max : `${host}report_max.php?`,
    report_pending_bottles:`${host}report_pending_bottles.php?`,
    report_pending_bottles_detailed: `${host}report_pending_bottles_detailed.php?`,
    report_sales_stats: `${host}report_sales_stats.php?`,
    get_customer_transactions: `${host}get_customer_transactions.php?`,
    get_agent_transactions: `${host}get_agent_transactions.php?`,
    get_agent_transactions_summary: `${host}get_agent_transactions_summary.php?`,
    get_agent_transactions_summary_mismatch_txn: `${host}get_agent_transactions_summary_mismatch_txn.php?`,
    report_sales_report: `${host}sales_report.php?`,
    report_store_b2w: `${host}report_store_b2w.php?`,
    report_product_trend_by_user: `${host}report_product_trend_by_user.php?`,
    report_performance_by_user: `${host}report_performance_by_user.php?`,
    report_product_stats_by_user: `${host}report_product_stats_by_user.php?`,

    // Tools
    send_notification: `${host}generic_send_notification.php?`,
    get_reason_by_type: `${host}get_reason_by_type.php?`,
    create_reason: `${host}create_reason.php?`,
    remove_reason: `${host}remove_reason.php?`,
    get_organisation_details: `${host}get_organisation_details.php?`,
    update_organisation_information: `${host}update_organisation_information.php?`,
    reset_invoice_no: `${host}reset_invoice_no.php?`,

    // WareHouse
    get_warehouses_url: `${host}get_warehouses.php?`,
    remove_wh: `${host}remove_wh.php?`,
    create_wh: `${host}create_wh.php?`,
    toggle_wh_status: `${host}toggle_wh_status.php?`,
    get_inventory_transactions: `${host}get_inventory_transactions.php?`,
    get_inventory_transactions_for_sd: `${host}get_inventory_transactions_for_sd.php?`,
    create_inventory_transaction: `${host}create_inventory_transaction.php?`,
    get_inventory_summary: `${host}get_inventory_summary.php?`,
    get_inventory_batchwise_summary: `${host}get_inventory_batchwise_summary.php?`,
    remove_inventory_transaction: `${host}remove_inventory_transaction.php?`,

    // Template
    get_templates_url: `${host}get_template_list.php?`,
    get_template_detail_url: `${host}get_template_details.php?`,
    remove_template: `${host}remove_template.php?`,
    create_template: `${host}create_template.php?`,

    // Payment
    update_payment: `${host}update_payment_details.php?`,
    get_payment_transactions : `${host}get_payment_transactions.php?`,


    //NEW

    // TOOLS
    get_job_actions: `${host}get_job_actions.php?`,
    create_job_action: `${host}create_job_action.php?`,
    remove_job_action: `${host}remove_job_action.php?`,
    get_roles: `${host}get_roles.php?`,

    // Items
    get_items: `${host}get_products.php?`,
    create_item: `${host}create_product.php?`,
    remove_item: `${host}remove_product.php?`,
    get_stock: `${host}get_stock.php?`,
    get_units : `${host}get_units.php?`,

    //Employees
    get_employees_url: `${host}get_users.php?type=USER&`,
    create_employee: `${host}create_user.php?type=USER&`,
    remove_employee: `${host}remove_user.php?type=USER&`,
    toggle_user_status: `${host}toggle_user_status.php?`,
    create_user: `${host}create_user.php?type=USER&`,
    get_users_url: `${host}get_users.php?type=USER&`,
    remove_users: `${host}remove_user.php?type=USER&`,
    toggle_users_status: `${host}toggle_user_status.php?`,

    //Transactions
    get_warehouse_transactions_new: `${host}get_warehouse_transactions_new.php?`,
    total_count: `${host}total_count.php?`,
    remove_inventory_transaction_new: `${host}remove_inventory_transaction_new.php?`,
    create_txn: `${host}create_warehouse_transaction_new.php?`,
    txn_summary: `${host}txn_summary.php?`,

    // Organisation Configuration
    get_organisations_url : `${host}get_organisation_configs.php?`,
    get_organisation_stats_url : `${host}get_organisation_stats.php?`,
    update_organisation: `${host}update_organisation.php?`,

    //report
    get_sales_report: `${host}report_daily_sales.php?`,
    get_purchase_report: `${host}report_daily_purchases.php?`,
    get_rate_report: `${host}report_rate_pattern.php?`,
    get_amount_report: `${host}report_amount.php?`,

    defaultAlerts: {
        import: {
            hr: "18",
            min: "0"
        }
    }
}

export default Config;

export const RowCountOptions = [10, 25, 50, 100];