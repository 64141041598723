import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./HomePage/home";
import Login from "./LoginPage/Login";
import Item from "./ItemPage/Item";
import Employee from "./EmployeePage/Employee";
import ManageUsers from "./UsersPage/User";
import WareHouse from "./WarehousePage/Warehouse";
import Organisation from "./OrganisationPage/Organisation";
import OrganisationStats from "./OrganisationPage/OrganisationStats";
import NotFound from "./NotFound/NotFound";

//Reports 
// import Summary from './Reports/SummaryPage/Summary';
import SalesReport from './Reports/SalesReportPage/SalesReport';
import PurchaseReport from './Reports/PurchaseReportPage/PurchaseReport';
import ProductReport from './Reports/ProductReportPage/ProductReport';

// Components
import AppliedRoute from "./components/AppliedRoute";

// Inventory
// import JobStatusTransaction from "./JobStatusTransactionPage/JobStatusTransaction";
import Transactions from "./TransactionPage/Transaction";
import Templates from "./TemplatePage";
// import InventoryTransactions from "./Inventory/InventoryTransactions";
// import InventoryStockSummary from "./Inventory/InventoryStockSummary";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path={`/login`} exact component={Login} props={childProps} />

    <AppliedRoute path={`/`} exact component={Home} props={childProps} />
    {/* <AppliedRoute path={`/transaction/jobstatus`} exact component={Import} props={childProps} /> */}
    {/* <AppliedRoute path={`/transaction/store`} exact component={Export} props={childProps} /> */}
    <AppliedRoute path={`/products`} exact component={Item} props={childProps} />
    <AppliedRoute path={`/accounts`} exact component={Employee} props={childProps} />

    {/* Transactions */}
    <AppliedRoute path={`/transactions/view`} exact component={Transactions} props={childProps} />

    {/* Reports */}
    <AppliedRoute path={`/report/daily/sales`} exact component={SalesReport} props={childProps} />
    <AppliedRoute path={`/report/daily/puchases`} exact component={PurchaseReport} props={childProps} />
    <AppliedRoute path={`/report/product/stats`} exact component={ProductReport} props={childProps} />
    {/* <AppliedRoute path={`/report/summary`} exact component={Summary} props={childProps} />  */}
    {/* <AppliedRoute path={`/transaction/jobstatus`} exact component={JobStatusTransaction} props={childProps} />
    <AppliedRoute path={`/transaction/store`} exact component={StoreTransaction} props={childProps} /> */}
    {/* Tools */}
    <AppliedRoute path={`/tools/warehouse`} exact component={WareHouse} props={childProps} />
    <AppliedRoute path={`/tools/templates`} exact component={Templates} props={childProps} />
    <AppliedRoute path={`/tools/manage/organisation`} exact component={Organisation} props={childProps} />
    <AppliedRoute path={`/tools/manage/organisation/stats`} exact component={OrganisationStats} props={childProps} />
    <AppliedRoute path={`/tools/manage/users`} exact component={ManageUsers} props={childProps} />

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;