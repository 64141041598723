import React, { Component } from "react";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Bar, Line } from 'react-chartjs-2';
import { WarehouseService } from "../../Services/WarehouseService";
import { ProductService } from "../../Services/ProductService";
import { Grid } from "@material-ui/core";
import { Typography, Paper, withStyles, Dialog, DialogContent, Slide } from '@material-ui/core';
import AutoCompleteSelection from '../../components/AutoCompleteSelection';
import { getTimeInIST, format_display_fancy_notime } from "../../Utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme =>({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    formControlAutocomplete: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(1),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(6),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '12pt',
        padding: theme.spacing(2)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    productSelection: {
        width: 300,
        marginTop: theme.spacing(2),
    }
  });

class ProductReport extends Component {

    constructor(props) {
      super(props);
      this.state = {
        startDate: moment().subtract(7, "days"),
        endDate: moment(),
        sales_chart_data: {},
        purchases_chart_data: {},
        purchases_rate_chart_data: {},
        sales_rate_chart_data: {},
        selected_product: {},
        product_master: [],
        open : false,
        total_purchase_qty : 0,
        total_sales_qty: 0,
        total_purchase_amt: 0,
        total_sales_amt: 0
      };
      props.setTitle('Productwise Report');
    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date,
        }, () => this.fetchData());
    }

    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date,
        }, () => this.fetchData());
    }

    componentDidMount(){
        this.fetchProducts();
    }

    handleProductSelection = (product) => {
        let {selected_product}  = this.state;
        selected_product = product;
        this.setState({selected_product}, () => this.fetchData());
    }

    fetchProducts = () => {
        ProductService.getItemList(global.organisation)
        .then((data) => {
            console.log(data);
            let product_master = [];
            data.products.filter( p => p.status === '1').forEach(element => {
                product_master.push({id: element.id, name: element.name, value: element.name, label: element.name, unit: element.unit});
            });
            this.setState({product_master, open: true});

        })
        .catch();
    }

    fetchData(){
        this.fetchPurchase();
        this.fetchSales();
        this.fetchPurchaseRatePattern();
        this.fetchSalesRatePattern();
        this.fetchTotalAmount();
    }

    fetchTotalAmount = () => {
        WarehouseService.getTotalAmount(global.organisation, this.state.startDate, this.state.endDate, undefined, this.state.selected_product.id)
        .then((data) => {
            console.log(data);
            let purchase_txn = data.report.filter(r => r.txn_type === "INWARD"), sales_txn = data.report.filter(r => r.txn_type === "OUTWARD");
            let total_purchase_amt = purchase_txn.length > 0 ? parseFloat(purchase_txn[0].total_amount) : 0;
            let total_sales_amt = sales_txn.length > 0 ? parseFloat(sales_txn[0].total_amount) : 0;
            this.setState({total_purchase_amt , total_sales_amt});
        })
        .catch(() => {
            console.log("Error while fetching");
        });
    }

    fetchPurchaseRatePattern = () => {
        WarehouseService.getRateReport(global.organisation, this.state.startDate, this.state.endDate, 'INWARD', this.state.selected_product.id)
        .then((data) => {
            let labels = [], puchases = [];
            data.report.forEach((element) => {
                labels.push(getTimeInIST(element.txn_date).format(format_display_fancy_notime));
                puchases.push(element.avg_rate);
            });
            this.props.isLoading(false);
            let purchases_rate_chart_data = {
              labels: labels,
              datasets: [{
                label: "Puchases",
                fill: false,
                data: puchases,
                borderColor: '#ff6383'
              }]
            };

            this.setState({purchases_rate_chart_data});
        })
        .catch((error) => {
            console.log(error);
        })
    }

    fetchSalesRatePattern = () => {
        WarehouseService.getRateReport(global.organisation, this.state.startDate, this.state.endDate, 'OUTWARD', this.state.selected_product.id)
        .then((data) => {
            let labels = [], sales = [];
            data.report.forEach((element) => {
                labels.push(getTimeInIST(element.txn_date).format(format_display_fancy_notime));
                sales.push(element.avg_rate);
            });
            this.props.isLoading(false);
            let sales_rate_chart_data = {
              labels: labels,
              datasets: [{
                label: "Sales",
                fill: false,
                data: sales,
                borderColor: '#2196f3'
              }]
            };

            this.setState({sales_rate_chart_data});
        })
        .catch((error) => {
            console.log(error);
        })
    }

    fetchPurchase = () => {
        WarehouseService.getPurchaseReport(global.organisation, this.state.startDate, this.state.endDate, this.state.selected_product.id)
        .then((data) => {
            let labels = [], puchases = [], backgroundColor = [];
            let total_purchase_qty = 0;
            data.report.forEach((element, index) => {
                labels.push(getTimeInIST(element.txn_date).format(format_display_fancy_notime));
                puchases.push(element.total_qty);
                backgroundColor.push("#ff6383");
                total_purchase_qty += parseFloat(element.total_qty);
            });
            this.props.isLoading(false);
            let purchases_chart_data = {
              labels: labels,
              datasets: [{
                label: "Puchases",
                fill: false,
                data: puchases,
                backgroundColor: backgroundColor
              }]
            };

            this.setState({purchases_chart_data, total_purchase_qty});
        })
        .catch((error) => {
            console.log(error);
        })
    }

    fetchSales = () => {
        WarehouseService.getSalesReport(global.organisation, this.state.startDate, this.state.endDate, this.state.selected_product.id)
        .then((data) => {
            let labels = [], sales = [], backgroundColor = [];
            let total_sales_qty = 0;
            data.report.forEach((element, index) => {
                labels.push(getTimeInIST(element.txn_date).format(format_display_fancy_notime));
                sales.push(element.total_qty);
                backgroundColor.push("#2196f3");
                total_sales_qty += parseFloat(element.total_qty);
            });
            this.props.isLoading(false);
            let sales_chart_data = {
              labels: labels,
              datasets: [{
                label: "Sales",
                fill: false,
                data: sales,
                backgroundColor: backgroundColor
              }]
            };
            this.setState({sales_chart_data, total_sales_qty});
        })
        .catch((error) => {
            console.log(error);
        })
    }

    handleClose = () => {
        this.setState({open: false});
    }

    render() {
        const { classes } = this.props;
        const options = {maintainAspectRatio: false};
        return (
            <div className="Home">
                <div style={{display: "flex"}}>
                    
                    <Grid container spacing={0}>
                        <Grid item lg={6} xs={12}>
                            <section className={classes.productSelection}>
                                {this.productDropDownUI()}
                            </section>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    inputVariant="outlined"
                                    margin="normal"
                                    label="From"
                                    value={this.state.startDate}
                                    onChange={this.handleChangeStartDate}
                                    format='DD MMM YYYY'
                                    maxDate={this.state.endDate}
                                    variant="inline"
                                    className={classes.formControl}
                                    style={{float: "left", marginTop: "30px"}}
                                    />
                                <DatePicker
                                    inputVariant="outlined"
                                    margin="normal"
                                    label="To"
                                    value={this.state.endDate}
                                    onChange={this.handleChangeEndDate}
                                    format='DD MMM YYYY'
                                    variant="inline"
                                    className={classes.formControl}
                                    style={{float: "left", marginTop: "30px"}}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </div>
                {
                    this.state.selected_product.id && 
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid item lg={12} xs={12}>
                                <Paper className={classes.paper} style={{paddingLeft: "5px", paddingRight: "5px"}}>
                                    <Grid container spacing={1} justify="space-between" alignItems="flex-start">
                                        <Grid item lg={4} xs={6}>
                                            <Typography variant="subtitle2" style={{fontSize: "1.1rem", lineHeight: 1.75, textAlign: "left", paddingLeft: 16}}>
                                                Total Purchase
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={2} xs={6}>
                                            <Typography variant="subtitle1" style={{fontSize: "1.1rem",  paddingRight: 16, textAlign: "right"}}  color="secondary">
                                                {(this.state.total_purchase_qty).toFixed(2)}&nbsp;{this.state.selected_product && this.state.selected_product.unit}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={4} xs={6}>
                                            <Typography variant="subtitle2" style={{fontSize: "1.1rem", lineHeight: 1.75, textAlign: "left", paddingLeft: 16}}>
                                                Total Sales
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={2} xs={6}>
                                            <Typography variant="subtitle1" style={{fontSize: "1.1rem", paddingRight: 16, textAlign: "right"}}  color="primary">
                                            {(this.state.total_sales_qty).toFixed(2)}&nbsp;{this.state.selected_product && this.state.selected_product.unit}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}  justify="space-between" alignItems="flex-start">
                                        <Grid item lg={4} xs={8}>
                                            <Typography variant="subtitle2" style={{fontSize: "1.1rem", lineHeight: 1.75, textAlign: "left", paddingLeft: 16}}>
                                                Total Purchase Amount
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={2} xs={4}>
                                            <Typography variant="subtitle1" style={{fontSize: "1.1rem", paddingRight: 16, textAlign: "right"}} color="secondary">
                                                {(this.state.total_purchase_amt).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={4} xs={8}>
                                            <Typography variant="subtitle2" style={{fontSize: "1.1rem", lineHeight: 1.75, textAlign: "left", paddingLeft: 16}}>
                                                Total Sales Amount
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={2} xs={4}>
                                            <Typography variant="subtitle1" style={{fontSize: "1.1rem", paddingRight: 16, textAlign: "right"}} color="primary">
                                            {(this.state.total_sales_amt).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Paper className={classes.paper} style={{width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
                                    <Typography variant="h6" >
                                        Purchase Stats
                                    </Typography>
                                    <Bar data={this.state.purchases_chart_data} options={options}/>
                                </Paper>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Paper className={classes.paper} style={{width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
                                    <Typography variant="h6" >
                                        Sales Stats
                                    </Typography>
                                    <Bar data={this.state.sales_chart_data} options={options}/>
                                </Paper>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Paper className={classes.paper} style={{width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
                                    <Typography variant="h6" >
                                        Purchase Rate Stats
                                    </Typography>
                                    <Line data={this.state.purchases_rate_chart_data} options={options}/>
                                </Paper>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Paper className={classes.paper} style={{width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
                                    <Typography variant="h6" >
                                        Sales Rate Stats
                                    </Typography>
                                    <Line data={this.state.sales_rate_chart_data} options={options}/>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                }
                {
                    !this.state.selected_product.id && 
                    <Dialog
                        scroll={"paper"}
                        open={this.state.open}
                        TransitionComponent={Transition}
                        fullWidth={true}
                        onClose={this.handleClose}
                        maxWidth='sm'
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent style={{height: 300}}>
                            {this.productDropDownUI()}
                        </DialogContent>
                    </Dialog>
                }
            </div>
        );
    }

    productDropDownUI = () => {
        let { classes } = this.props;
        return ( <AutoCompleteSelection
                    placeHolder="Select Product"
                    className={classes.formControlAutocomplete}
                    onChange={this.handleProductSelection}
                    name="product"
                    isMulti={false}
                    value={[this.state.selected_product]}
                    options={this.state.product_master} />);
    }
}


export default withStyles(styles)(ProductReport);