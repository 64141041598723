import Config from '../Config';
import { RestService } from './RestService';

export const TransactionService = {
    getTransactions: async function(organisation, start, numberItems, startdate, enddate, products, warehouses, txn_type){
        return RestService.get(`${Config.get_warehouse_transactions_new}organisation=${organisation}&from=${start}&offset=${numberItems}${ products ? `&products=${products}` : ''}${ startdate ? `&startdate=${startdate}` : ''}${ enddate ? `&enddate=${enddate}` : ''}${ txn_type ? `&txn_type=${txn_type}` : ''}${ warehouses ? `&warehouses=${warehouses}` : ''}`, {})
        .then(response => response.json());
    },
    getTotalCount: async function(organisation, startdate, enddate, products, warehouses, txn_type) {
        return RestService.get(`${Config.total_count}organisation=${organisation}&data_type=TRANSACTIONS${ products ? `&products=${products}` : ''}${ warehouses ? `&warehouses=${warehouses}` : ''}${ txn_type ? `&txn_type=${txn_type}` : ''}${ startdate ? `&startdate=${startdate}` : ''}${ enddate ? `&enddate=${enddate}` : ''}`, {})
        .then(response => response.json());
    },
    getTodaysStats: async function(organisation, date, txn_type) {
        return RestService.get(`${Config.txn_summary}organisation=${organisation}${ txn_type ? `&txn_type=${txn_type}` : ''}${ date ? `&date=${date}` : ''}`, {})
        .then(response => response.json());
    },
    createTransaction: async function(organisation, headers, body){
        return RestService.post(`${Config.create_txn}organisation=${organisation}`, headers, body);
        // .then(response => response.json());
    },
    deleteTransaction: async function(organisation, headers, id){
        return RestService.delete(`${Config.remove_inventory_transaction_new}organisation=${organisation}&id=${id}`, headers);
    }
};