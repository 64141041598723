import Config from '../Config';
import { RestService } from './RestService';

export const TemplateService = {
    getTemplates: async function(organisation){
        return RestService.get(`${Config.get_templates_url}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    getTemplateDetail: async function(id, organisation){
        return RestService.get(`${Config.get_template_detail_url}organisation=${organisation}&id=${id}`, {})
        .then(response => response.json());
    },
    create: async function(body, organisation, headers){
        return RestService.post(`${Config.create_template}organisation=${organisation}&`, headers, body)
        .then(response => response.json());
    },
    remove: async function(id, organisation, headers = {}){
        return RestService.delete(`${Config.remove_template}organisation=${organisation}&id=${id}`, headers, {});
        // .then(response => response.json());
    },
};