import React, { Component } from "react";
import { OrganisationService } from "../Services/OrganisationService";
import {getTimeInIST, format_display_withtime} from '../Utils';

import {
    Paper,
    Typography,
    withStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    table: {
        // overflowX: 'auto',
        // display: 'block',
        minWidth: 500,
    },
  });

class OrganisationStats extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: []
      }
      props.setTitle("Organisation Stats");
    }
 
    componentDidMount(){
        this.fetchData();
    }


    fetchData(){
        this.props.isLoading(true);
        OrganisationService.getOrganisationStats(global.organisation)
        .then((data) => {
            console.log(data);
            this.setState({data: data.organisations,  originalData: data.organisations});
            this.filterCriteria();
            this.props.isLoading(false);
        })
        .catch(error => {
            console.error(error);
            this.props.isLoading(false);
        });
    }

    updateRows = (data) => {
        let rows = [];
        data.forEach((organisation) => {
            rows.push(<OrganisationDetails key={organisation.organisation} organisation={organisation}/>);
          });
        this.setState({rows: rows});
    }

    filterCriteria = (e) => {
        this.props.isLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value;
        }
        let filteredList = this.state.originalData.filter(data => data.organisation.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Organisation'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    render() {
        const { classes } = this.props;
        const { data, message } = this.state;
        return (
            <div className="Home">
                {/* <h3 style={{marginBottom: '5px'}}>Organisations <Badge>{this.state.data.length}</Badge></h3> */}
                <div>
                    <Paper className={classes.root} style={{width: "100%", paddingTop : '15px', paddingLeft: "5px", paddingRight: "5px", marginTop: '10px'}}>
                        {/* <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form> */}
                        <EnhancedTableToolbar title={"Organisations:"} subtitle={data.length} onSearchTextChange={this.filterCriteria}/>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Created On</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Subscription Ends on</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1" style={{maxWidth: 100}}>Last Login</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1" style={{maxWidth: 50}}>Total Users</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1" style={{maxWidth: 50}}>Total Warehouses</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1" style={{maxWidth: 100}}>Inward Transaction (last 30 days)</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1" style={{maxWidth: 100}}>Outward Transaction (last 30 days)</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                        {
                            message && <p style={{color:"red", paddingTop: 16, fontSize: "14pt", paddingLeft:"20px", width: "100%", textAlign: "center"}}>{message}</p>
                        }
                    </Paper>
                </div>
            </div>
        );
    }

}


class OrganisationDetails extends Component {

    render(){
      const organisation = this.props.organisation;
      return (
        <TableRow key={organisation.organisation}>
            <TableCell component="th" scope="row">
                {organisation.organisation}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                {getTimeInIST(organisation.created_date).format(format_display_withtime)}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                {organisation.license_end_date ? getTimeInIST(organisation.license_end_date).format(format_display_withtime) : "-"}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                {getTimeInIST(organisation.last_login).format(format_display_withtime)}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                {organisation.users}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                {organisation.warehouse_count}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                {organisation.inward_count_last_30_days}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                {organisation.outward_count_last_30_days}
            </TableCell>
        </TableRow>
      );
    }
}

export default withStyles(styles)(OrganisationStats);