import Config from '../Config';
import {RestService} from './RestService';

export const UserService = {
    getEmployeesList: async function(organisation, start, numberItems, filter, sort, direction){
        return RestService.get(`${Config.get_employees_url}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    createEmployee: async function(organisation, headers, body) {
        return RestService.post(`${Config.create_employee}organisation=${organisation}&`, headers, body)
        .then(response => response.json());
    },
    removeEmployee: async function(organisation, id) {
        return RestService.post(`${Config.remove_employee}organisation=${organisation}&id=${id}`, {})
        .then(response => response.json());
    },
    toggleStatus: async function(organisation, id) {
        return RestService.get(`${Config.toggle_user_status}organisation=${organisation}&user_id=${id}`, {})
        .then(response => response.json())
    },
    getContractorList: async function(organisation){
        return RestService.get(`${Config.get_contractors_url}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    removeContractor: async function(organisation, id) {
        return RestService.post(`${Config.remove_contractor}organisation=${organisation}&id=${id}`, {})
        .then(response => response.json());
    },
    createContractor: async function(organisation, headers, body) {
        return RestService.post(`${Config.create_contractor}organisation=${organisation}&`, headers, body)
        .then(response => response.json());
    },

    getTotalCount: async function(organisation, type) {
        return RestService.get(`${Config.total_count}organisation=${organisation}&type=${type}&data_type=USER`, {})
        .then(response => response.json());
    },
    authenticate: async function(organisation, body){
        return RestService.post(`${Config.authenticate_url}organisation=${organisation}`, {}, body)
                .then(response => response.json());
    },

    createUser: async function(headers, body, organisation) {
        return RestService.post(`${Config.create_user}organisation=${organisation}`, headers, body)
        .then(response => response.json());
    },
    getUsersList: async function(organisation, start, numberItems, filter, sort, direction){
        return RestService.get(`${Config.get_users_url}organisation=${organisation}`, {})
        .then(response => response.json());
    },
    removeUsers: async function(id, organisation) {
        return RestService.post(`${Config.remove_users}organisation=${organisation}&id=${id}`, {})
        .then(response => response.json());
    },
    toggleStatusForUser: async function(id, organisation) {
        return RestService.get(`${Config.toggle_users_status}organisation=${organisation}&user_id=${id}`, {})
        .then(response => response.json())
    },
};