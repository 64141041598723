import {
	Fab,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MyDialog from "../components/MyDialog";
import AddIcon from "@material-ui/icons/Add";
import { TemplateService } from "../Services/TemplateService";
import { StyledTableCell } from "../Utils";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddTemplate from "./AddTemplate";

const useStyles = makeStyles((theme) => ({
	fab: {
		margin: theme.spacing(1),
		position: "absolute",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	root: {
		width: "100%",
		marginTop: theme.spacing(3),
		overflowX: "auto",
		paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	upload: {
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: "30%",
		},
		marginTop: theme.spacing(3),
		overflowX: "auto",
		paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	margin: {
		margin: theme.spacing(1),
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	paper: {
		position: "absolute",
		width: theme.spacing(50),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		outline: "none",
	},
	table: {
		// overflowX: 'auto',
		// display: 'block',
		minWidth: 500,
	},
}));

function Templates(props) {
	const classes = useStyles();
	const { setTitle, showConfirmDialog, closeConfirmDialog, isLoading } = props;
	const [templates, setTemplates] = useState([]);
	const [showDetailsFlag, setShowDetailsFlag] = useState(false);
	const [showEditDialogFlag, setEditDialogFlag] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [selectedTemplateId, setSelectedTemplateId] = useState(null);
	const [inward, setInward] = useState(null);
	const [outward, setOutward] = useState(null);

	useEffect(() => {
		setTitle("Manage Templates");
		fetchData();
	}, []);

	const fetchData = () => {
		isLoading(true);
		TemplateService.getTemplates(global.organisation)
			.then((data) => {
				console.log(data);
				setTemplates(data.templates);
			})
			.catch((error) => console.error(error))
			.finally(() => isLoading(false));
	};

	const edit = (template) => { 
		setSelectedTemplateId(template.id);
		setEditDialogFlag(true)
	};

	const remove = (template) => {
		showConfirmDialog("Confirm Remove?", undefined, () => {
			closeConfirmDialog();
			isLoading(true);
			TemplateService.remove(template.id, global.organisation)
				.then(() => {
					fetchData();
				})
				.catch((error) => console.error(error))
				.finally(() => isLoading(false));
		});
	};
	const details = (template) => {
		isLoading(true);
		TemplateService.getTemplateDetail(template.id, global.organisation)
			.then((data) => {
				if (data && data.id) {
					setSelectedTemplate(template)
					setInward(data.items.filter(i => i.txn_type === 'INWARD'))
					setOutward(data.items.filter(i => i.txn_type === 'OUTWARD'))
					setShowDetailsFlag(true)
				}
			})
			.catch((error) => console.error(error))
			.finally(() => isLoading(false));
	};

	return (
		<div className="Home">
			<div
				style={{
					width: "100%",
					paddingTop: "15px",
					paddingLeft: "5px",
					paddingRight: "5px",
					marginTop: "10px",
				}}
			>
				<Grid container spacing={1} justify="center">
					<Grid item lg={6} xs={12}>
						<Paper className={classes.root}>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell>
											<Typography variant="subtitle1">Template Name</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="subtitle1">Ratio Enabled</Typography>
										</TableCell>
										<TableCell align="center"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{templates.map((template, i) => {
										return (
											<TableRow key={i}>
												<TableCell component="th" scope="row">
													{template.name}
												</TableCell>
												<TableCell
													align="center"
													style={{ whiteSpace: "pre-wrap" }}
												>
													{template.ratio_enabled === 1 ? <CheckCircleIcon color="primary" /> : <CheckCircleIcon style={{color: '#ddd'}} />}
												</TableCell>
												<TableCell align="right">
													<Tooltip title="Details">
														<IconButton
															aria-label="edit"
															onClick={(e) => details(template)}
														>
															<Icon>library_books</Icon>
														</IconButton>
													</Tooltip>
													<Tooltip title="Edit">
														<IconButton
															aria-label="edit"
															onClick={(e) => edit(template)}
														>
															<Icon>edit_icon</Icon>
														</IconButton>
													</Tooltip>
													<Tooltip title="Remove">
														<IconButton
															aria-label="remove"
															onClick={(e) => remove(template)}
														>
															<Icon>delete</Icon>
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
				</Grid>
			</div>
			<Tooltip title="Add Template">
				<Fab
					onClick={edit}
					color="primary"
					aria-label="Add"
					className={classes.fab}
				>
					<AddIcon />
				</Fab>
			</Tooltip>
			<MyDialog
				maxWidth={"md"}
				content={
					<Grid container spacing={1}>
						{
							inward && (
								<Grid item xs={12} lg={6}>
									<Typography variant="subtitle1">INWARD Products</Typography>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Product</TableCell>
												{selectedTemplate.ratio_enabled === 1 && <TableCell>Ratio Qty</TableCell>}
											</TableRow>
										</TableHead>
										<TableBody>
											{
												inward.map((product, i) => (
													<TableRow key={i}>
														<StyledTableCell>{product.product.name}</StyledTableCell>
														{selectedTemplate.ratio_enabled === 1 && <StyledTableCell>{product.ratio}</StyledTableCell>}
													</TableRow>
												))
											}
										</TableBody>
									</Table>
								</Grid>
							)
						}
						{
							outward && (
								<Grid item xs={12} lg={6} style={inward && outward && { borderLeftColor: 'gray', borderLeftStyle: 'solid', borderLeftWidth: 2 }}>
									<Typography variant="subtitle1">OUTWARD Products</Typography>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Product</TableCell>
												{selectedTemplate.ratio_enabled === 1 && <TableCell>Ratio Qty</TableCell>}
											</TableRow>
										</TableHead>
										<TableBody>
											{
												outward.map((product, i) => (
													<TableRow key={i}>
														<StyledTableCell>{product.product.name}</StyledTableCell>
														{selectedTemplate.ratio_enabled === 1 && <StyledTableCell>{product.ratio}</StyledTableCell>}
													</TableRow>
												))
											}
										</TableBody>
									</Table>
								</Grid>
							)
						}
					</Grid>
				}
				title={selectedTemplate ? selectedTemplate.name : ""}
				open={showDetailsFlag}
				close={() => {
					setShowDetailsFlag(false);
					setSelectedTemplate(null);
					setInward(null)
					setOutward(null)
				}}
			/>
			<AddTemplate
				{...props}
				show={showEditDialogFlag}
				id={selectedTemplateId}
				close={(reload = false) => {
					if(reload){
						fetchData()
					}
					setEditDialogFlag(false)
					setSelectedTemplateId(null)
				}}
			/>
		</div>
	);
}

export default Templates;
