import React, { Component } from "react";
import "../css/employee.css";
import { Form } from 'react-bootstrap';
import {Button, Chip} from '@material-ui/core';
import { UserService } from "../Services/UserService";
import { RoleService } from "../Services/RoleService";
import AddIcon from '@material-ui/icons/Add';
import {
    IconButton,
    Tooltip,
    Icon,
    Paper,
    withStyles,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(2), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    table: {
        minWidth: 500,
    },
  });

class Employee extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        new_employee: { name: "", mobile_no: "", code: "", email: "", roles: []},
        show: false,
        master_roles: new Map(),
        rolesCheckBox: []
      }
      props.setTitle("Manage Accounts");

      this.toggleStatus = this.toggleStatus.bind(this);
      this.createModifyEmployee = this.createModifyEmployee.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
      this.edit = this.edit.bind(this);
      this.remove = this.remove.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.hasAdmin = this.hasAdmin.bind(this);
    }
 
    componentDidMount(){
        this.fetchRoles();
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_employee: { name: "", mobile_no: "", code: "", email: "", roles: []}
        });
        this.resetCheckBoxes();
    }

    handleShow() {
        this.setState({ show: true });
    }

    fetchData(){
        this.props.isLoading(true);
        UserService.getEmployeesList(global.organisation)
        .then((data) => {
            console.log(data);
            this.setState({data: data.users,  originalData: data.users});
            this.updateRows(data.users);
            this.props.isLoading(false);
        })
        .catch(error => {
            console.log(error);
            this.props.isLoading(false);
        });
    }

    updateRows(data){
        let rows = [];
        data.forEach((user) => {
            rows.push(<UserDetails key={user.id} user={user} toggleStatus={this.toggleStatus} edit={this.edit} remove={this.remove} roles={this.state.master_roles}/>);
        });
        this.setState({rows: rows});
    }

    toggleStatus(e,user){
        e.preventDefault();
        UserService.toggleStatus(global.organisation, user.id)
        .then((data) => {
            console.log(data);
            const status = data.status === "0" ? "Deactivated": "Activated";
            this.props.showSnackBar(`${status} employee ${data.name} Account`, 'info');
            this.fetchData();
        })
        .catch(error => console.log(error));
    }

    fetchRoles(){
        this.props.isLoading(true);
        RoleService.getRoleList(global.organisation)
        .then((data) => {
            data = data.roles;
            let roleDetailMap = new Map();
            data.forEach(role => {
                roleDetailMap.set(role.id, role);
            });
            this.setState({master_roles: roleDetailMap},  this.fetchData());
            let rows = [];
            data.forEach((role) => {
                rows.push(
                    <Tooltip key={role.id} title={role.description}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={false}
                                    onChange={this.handleChangeCheckBox}
                                    color="primary"
                                    name={role.id}
                                    />
                            }
                            label={role.name}
                            />
                    </Tooltip>
                );
            });
            this.setState({rolesCheckBox: rows});
            this.props.isLoading(false);
        })
        .catch(error => {
            console.log(error);
            this.props.isLoading(false);
        });
    }

    filterCriteria = (e) => {
        this.props.isLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value;
        }
        
        let filteredList = this.state.originalData.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Employee'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    handleChange(e) {
        e.preventDefault();
        let change = this.state.new_employee;
        change[e.target.name] = e.target.value;
        this.setState(change);
    }

    handleChangeCheckBox(e) {
        let change = this.state.new_employee;
        if(e.target.checked && !change['roles'].includes(e.target.name)){
            change['roles'].push(e.target.name);
        } else {
            var index = change['roles'].indexOf(e.target.name);
            change['roles'].splice(index, 1);
        }
        this.setState({new_employee: change});
    }

    hasAdmin() {
        let hasAdminRole = false;
        this.state.new_employee.roles.forEach((roleId) => {
            if(this.state.master_roles.get(roleId).name === "ADMIN"){
                hasAdminRole = true;
            }
        });
        return hasAdminRole;
    }

    createModifyEmployee(e){
        e.preventDefault();
        if(this.state.new_employee.name && this.state.new_employee.mobile_no && this.state.new_employee.code.length >= 4 && this.state.new_employee.roles.length > 0){
            if(this.hasAdmin() || (!this.hasAdmin() && (!this.state.new_employee.id || (this.state.new_employee.id && this.state.new_employee.id !== this.props.user.id)))){
                this.props.showConfirmDialog("Confirm?", undefined, () => {
                    this.createEmployeeAPI(this.state.new_employee);
                    this.props.closeConfirmDialog();
                });
            } else {
                this.props.showConfirmDialog("Cannot remove Admin role for currently logged in user", undefined, undefined);
            }
        } else if(this.state.new_employee.name && this.state.new_employee.mobile_no && this.state.new_employee.roles.length === 0){
            this.props.showSnackBar(`Please select atleast one role`, 'error');
        } else if(this.state.new_employee.code.length < 4){
            this.props.showSnackBar(`Code should be atleast 4 characters`, 'error');
        } else {
            this.props.showSnackBar(`Please fill all the details and continue`, 'error');
        }
    }

    remove(e, user){
        e.preventDefault();
        if(this.props.user.id !== user.id){
            this.props.showConfirmDialog("Confirm removal of Account?", undefined, () => {
                this.removeEmployeeAPI(this.dialog, user.id);
                this.props.closeConfirmDialog();
            });
        } else {
            this.props.showConfirmDialog("Cannot remove yourself", undefined, undefined);
        }
    }

    removeEmployeeAPI(dialog, id){
        UserService.removeEmployee(global.organisation, id)
        .then((data) => {
            console.log(data);
            this.fetchData();
        })
        .catch((error) => {
            this.props.showConfirmDialog("Could not remove Account. Please try again.", undefined, undefined);
        });
        
    }

    edit(e, user){
        e.preventDefault();
        if(user){
            let v= this.handleChangeCheckBox;
            this.setState({new_employee: user}, () => {
                let rows = [];
                this.state.master_roles.forEach((value, key, map) => {
                    rows.push(
                        <Tooltip title={value.description} key={key}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={user.roles.includes(value.id)}
                                        onChange={v}
                                        color="primary"
                                        name={value.id}
                                        />
                                }
                                label={value.name}
                            />
                        </Tooltip>
                    );
                });
                this.setState({rolesCheckBox: rows});
                this.handleShow();
            });
        } else {
            this.handleShow();
        }
    }

    resetCheckBoxes(){
        let rows = [];
        this.state.master_roles.forEach((value, key, map) => {
            rows.push(
                <Tooltip title={value.description} key={key}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={false}
                                    onChange={this.handleChangeCheckBox}
                                    color="primary"
                                    name={value.id}
                                    />
                            }
                            label={value.name}
                        />
                </Tooltip>
            );
        });
        this.setState({rolesCheckBox: rows});
    }

    createEmployeeAPI(new_employee){
        //new_employee.code = 1234;
        let body = new_employee;
        this.props.isLoading(true);
        let headers = {user_id : this.props.user.id };
        UserService.createEmployee(global.organisation, headers, body)
        .then((response) => {
            if(response.status === 409){
                this.props.showSnackBar(`Employee already exists with the same mobile no. Please check again.`, 'error');
            } else {
                console.log(response);
                this.setState({
                    new_employee: { name: "", mobile_no: "", code: "", roles: []}
                });
                this.fetchData();
                if(this.state.show) this.handleClose();
            }
            this.props.isLoading(false);
        })
        .catch((error) => {
            this.props.isLoading(false);
            this.props.showConfirmDialog(`Failed to create Employee. Please try again.`, undefined, undefined);
        });
        
    }

    render() {
        let message = this.state.message;
        let {classes} = this.props;
        let {data} = this.state;
        return (
            <div className="Home">
                {/* <h3>Accounts <Badge>{this.state.data.length}</Badge></h3> */}
                <div >
                    <Paper className={classes.root}>
                        {/* <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form> */}
                        <EnhancedTableToolbar title={"Accounts:"} subtitle={data.length} onSearchTextChange={this.filterCriteria}/>
                        <Table className={classes.table}>
                            <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Mobile No</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Roles</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Email</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Status</Typography></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                        {
                            message && <p style={{color:"red", paddingTop: 16, fontSize: "14pt", paddingLeft:"20px", width: "100%", textAlign: "center"}}>{message}</p>
                        }
                    </Paper>
                </div>
                <Dialog disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.show}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'
                    style={{marginTop : "5%"}}>
                    <DialogTitle id="alert-dialog-title">{this.state.show ? "User" : ""}</DialogTitle>
                    <DialogContent>
                        {this.userForm( "Edit User", this.createModifyEmployee, "Save")}
                    </DialogContent>
                </Dialog>
                <Tooltip title="Add User">
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        );
    }

    userForm = (title, submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="name"
                label="Name"
                placeholder="Name"
                name="name"
                defaultValue={this.state.new_employee.name}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <TextField
                id="mobile_no"
                label="mobile_no"
                placeholder="Mobile no"
                name="mobile_no"
                defaultValue={this.state.new_employee.mobile_no}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                pattern="[0-9]{10}"
                type="text"
                InputProps={{
                    inputProps: { min: 0, maxLength: 10 }
                }}
                />
            <TextField
                id="outlined-email-input"
                label="Email"
                className={this.props.classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                defaultValue={this.state.new_employee.email}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                variant="outlined"
                />
            <TextField
                id="code"
                label="code"
                placeholder="Code"
                name="code"
                defaultValue={this.state.new_employee.code}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                pattern="[0-9]{4}"
                type="text"
                InputProps={{
                    inputProps: { min: 0, maxLength: 4 }
                }}
                />
                <FormGroup row>
                    {this.state.rolesCheckBox}
                </FormGroup>
                <Button type="submit" onClick={submitFunction} style={{marginRight: "10px"}}>{buttonText}</Button>
                {this.state.show ? (<Button onClick={this.handleClose}>Close</Button>): ""}
        </Form>
    )

}

class UserDetails extends Component {

    render(){
      const user = this.props.user;
      const roles = this.props.roles;
        return (
            <TableRow key={user.id} style={{backgroundColor : user.status === "0" ?  "#f6685e" : "#a2cf6e", color: "white"}}>
                <TableCell component="th" scope="row"><Typography variant="subtitle2">{user.name}</Typography></TableCell>
                <TableCell align="center"><Typography variant="subtitle2">{user.mobile_no}</Typography></TableCell>
                <TableCell align="center"><Typography variant="subtitle2">
                    {
                        
                        user.roles.map((u, i) => <Chip key={i} style={{margin: 5}} color="primary" label={roles.get(u).name}/>)
                    }
                    </Typography>
                </TableCell>
                <TableCell align="center"><Typography variant="subtitle2">{user.email ? user.email : "-"}</Typography></TableCell>
                <TableCell align="center"><Typography variant="button">{user.status === "0" ? "Deactive" : "Active"}</Typography></TableCell>
                <TableCell align="right">
                    <Tooltip title="Activate/Deactivate">
                        <IconButton aria-label="Activate/Deactivate" onClick={(e) => this.props.toggleStatus(e, user)}>
                            <Icon>power_settings_new</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton aria-label="Edit" onClick={(e) => this.props.edit(e, user)}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton aria-label="Remove" onClick={(e) => this.props.remove(e, user)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(Employee);