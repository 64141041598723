import React, { Component } from "react";
import "../css/home.css";
import { ItemService } from "../Services/ItemService";
import {randomHex, format_display, format_without_time, currentTimeInIST, format_display_fancy_notime_withDay, formatNumber} from '../Utils';
import EnhancedTableHead from '../components/EnhancedTableHead';
import EnhancedTableToolbar from '../components/EnhancedTableToolbar';
import classnames from 'classnames';
import {WarehouseService} from "../Services/WarehouseService";
import { Doughnut } from 'react-chartjs-2';
import jsPDF from 'jspdf';
import moment from 'moment';

import {
    Paper,
    TableBody,
    withStyles,
    Table,
    TableCell,
    TableRow,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
	Grid,
	Divider,
} from '@material-ui/core';
import { ROLES } from "../Roles";
import { TransactionService } from "../Services/TransactionService";

const styles = theme =>({
  fab: {
      margin: theme.spacing(1),
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    paddingTop : theme.spacing(1), 
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  section: {
	width: '100%',
	height: 235,
	overflow: 'auto',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    paddingTop : theme.spacing(1), 
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  tablecell: {
      fontSize: '12pt',
	  padding: theme.spacing(1),
	  
  },
  paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      width: "80%",
      outline: 'none',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginBottom : '20px',
  },
  table: {
		display: 'inline-block',  
	  	[theme.breakpoints.up('md')]: {
			minWidth: 500,
			display: 'inline-table',  
		},
	  	overflow: 'auto'
  },
  list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
  },
  grid: {
      [theme.breakpoints.up('md')]: {
          marginTop: '10px',
      },
      marginTop: '50px',
      marginBottom: '5px'
  },
  textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  },
  qtyField: {
      width: "40%",
      position: "relative",
      [theme.breakpoints.up('md')]: {
          right: '100px',
          width: "30%",
      },
      right: "22px"
  },
  icon: {
      padding: '0px',
      [theme.breakpoints.up('md')]: {
          padding: '12px',
      },
  },
  itemQtySection: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '40%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    minWidth: 200,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(0),
    },
},
button: {
  marginTop: theme.spacing(3),
  height: theme.spacing(7),
  float: 'right',
  marginLeft: theme.spacing(2),
},
message: {
	padding: 10,
	color: 'red'
}
});

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			originalData: [],
			current_rows: [],
			chart_data: {},
			columns : [
				{ id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
				{ id: 'stock', numeric: true, disablePadding: true, label: 'Stock available' , sortable: true },
				{ id: 'warehouse', numeric: false, disablePadding: true, label: 'Warehouse', sortable: true  },
				{ id: 'total_value', numeric: false, disablePadding: true, label: 'Total Value', sortable: false  },
				{ id: 'average_price', numeric: true, disablePadding: true, label: 'Avg Purchase Price', sortable: false  },
				{ id: 'threshold', numeric: true, disablePadding: false, label: 'Threshold', sortable: true  },
			],
			order: 'asc',
			orderBy: 'name',
			items: [],
			selected_warehouse: "-1",
			startdate: moment().startOf('isoWeek'),
			enddate: moment().endOf('isoWeek'),
			transaction_count: 0,
			inward_row: [],
			outward_row: [],
			todays_stats: {
				total_amount: 0,
				count: 0
			},
			yesterdays_stats: {
				total_amount: 0,
				count: 0
			},
			total_rows: 0
		}

		props.setTitle('Dashboard');
	}

	componentDidMount(){
		if(this.props.user.roles.includes(ROLES.SUPER_ADMIN)){
			this.props.history.push("/tools/manage/organisation/stats");
		}
		this.fetchWarehouses();
		this.refresh();
	}

	refresh = () => {
		this.fetchItems();
		this.fetchSummary();
		this.fetchTodaysStats();
	}

	exportReport = (e) => {
		var doc = new jsPDF({
			orientation: 'portrait',
			format: 'a4'
		});
		doc.cellInitialize();
		doc.setFontSize(14);
		doc.setLineHeightFactor(0.5);
		doc.setFontStyle('bold');

		let items = [];
		if(this.state.selected_warehouse !== "-1"){
			items = this.state.items.filter((i) => i.warehouse_id === this.state.selected_warehouse)
		} else {
			items = this.state.items;
		}

		doc.text("Stock Details", 10, 10);
		doc.text(currentTimeInIST().format(format_display), doc.internal.pageSize.width - 50, 10);
		
		//Columns
		doc.setFontSize(12);
		doc.cell(10, 20, 80, 10, "Warehouse", 0, "left");
		doc.cell(10, 20, 50, 10, "Name", 0, "left");
		doc.cell(10, 20, 50, 10, "Stock Available", 0, "right");

		doc.setFontStyle('none');
		items.forEach((item, index) => {
			doc.cell(10, 20, 80, 10, item.warehouse, index + 1, "left");
			doc.cell(10, 20, 50, 10, item.name, index + 1, "left");
			doc.cell(10, 20, 50, 10, item.stock.toString(), index + 1, "right");
		});

		let filename = 'Stock Details.pdf';
		// doc.output("dataurlnewwindow",{filename: filename});
		doc.save(filename);
  	}

	fetchWarehouses = () => {
		WarehouseService.getWarehouses(global.organisation)
		.then((data) => {
			let warehouse_names = data.warehouse.map((w) => (
				<MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
			));
			warehouse_names.unshift(<MenuItem key={"-1"} value={"-1"}>All Warehouses</MenuItem>);
			this.setState({master_warehouse: data.warehouses, warehouse_names: warehouse_names});
		})
		.catch((error) =>{
			console.log("Error while fetching Warehouse list");
		});
	}

	fetchSummary = () => {
		TransactionService.getTotalCount(global.organisation, moment.utc(this.state.startdate).format(format_without_time), moment.utc(this.state.enddate).format(format_without_time), undefined, undefined, undefined)
        .then((data) => {
			console.log(data);
			let inward_row = [];
            if(data.summary.INWARD.length > 0){
				let total_amount = 0;
				inward_row.push(<Typography display="block" key={"INWARD"} className={this.props.classes.typography} variant="h6" >Purchases</Typography>);
				
                data.summary.INWARD.forEach((element, index) => {
					total_amount += parseFloat(element.total_amount);
                    inward_row.push(<Typography display="block"  key={"INWARD" + index} className={this.props.classes.typography} variant="caption">{parseFloat(element.total_qty).toFixed(2)} {element.unit} | {formatNumber(element.total_amount)}</Typography>)
				});
				inward_row.push(<Typography display="block"  key={"INWARD_TOTAL"} className={this.props.classes.typography} variant="caption" color="secondary" style={{marginTop: 10}}>Total: {formatNumber(total_amount)}</Typography>);
			} 
			
			let outward_row = [];
            if(data.summary.OUTWARD.length > 0){
				let total_amount = 0;
                outward_row.push(<Typography display="block" key={"OUTWARD"} className={this.props.classes.typography} variant="h6">Sales</Typography>);
                data.summary.OUTWARD.forEach((element, index) => {
					total_amount += parseFloat(element.total_amount);
                    outward_row.push(<Typography display="block" key={"OUTWARD" + index} className={this.props.classes.typography} variant="caption">{parseFloat(element.total_qty).toFixed(2)} {element.unit} | {formatNumber(element.total_amount)}</Typography>)
				});
				outward_row.push(<Typography display="block" key={"OUTWARD_TOTAL"} className={this.props.classes.typography} variant="caption" color="primary" style={{marginTop: 10}}>Total: {formatNumber(total_amount)}</Typography>);
			}
			
			this.setState({"transaction_count": data.count, inward_row, outward_row});
        })
        .catch(error => {
            console.log(error);
        });
	}

	fetchTodaysStats = () => {
		TransactionService.getTodaysStats(global.organisation, moment().format(format_without_time), 'OUTWARD')
        .then((data) => {
			console.log(data);
			let total_amount = 0, yesterdays_amount = 0;
            if(data.summary.today.length > 0){
				total_amount = data.summary.today.reduce(function(accumulator, currentValue){
					return accumulator + parseFloat(currentValue.total_amount) ;
				}, total_amount);
			}

			if(data.summary.yesterday.length > 0){
				yesterdays_amount = data.summary.yesterday.reduce(function(accumulator, currentValue){
					return accumulator + parseFloat(currentValue.total_amount) ;
				}, yesterdays_amount);
			}
			
			this.setState({todays_stats : {total_amount : total_amount, count: data.summary.count.today}, yesterdays_stats: {total_amount : yesterdays_amount, count: data.summary.count.yesterday}});
        })
        .catch(error => {
            console.log(error);
        });
	}

	fetchItems = () => {
		this.props.setLinearLoading(true);
		ItemService.getStock(global.organisation)
		.then((data) => {
			console.log(data);
			let labels = [], stocks = [], backgroundColor = [];
			for (let i=0; i<data.stock.length; i++){
				data.stock[i].stock = parseFloat(data.stock[i].stock)
				data.stock[i].threshold = parseFloat(data.stock[i].threshold)
				labels.push(data.stock[i].name);
				stocks.push(data.stock[i].stock);
				backgroundColor.push(randomHex());
			}
			this.updateRows(data.stock);

			this.props.isLoading(false);
			let chart_data = {
			labels: labels,
			datasets: [{
				label: "Stock Details",
				data: stocks,
				backgroundColor: backgroundColor
				}]
			};

			let unitList = new Set(data.stock.map(s => s.product_unit));
			let total_rows = [];
			unitList.forEach((unit, index) => {
				
				let total_qty = 0, total_amount = 0;
				let stock = data.stock.filter(s => s.product_unit === unit);
				total_qty = stock.reduce(function(accumulator, currentValue){
					return accumulator + parseFloat(currentValue.stock) ;
				}, total_qty);

				total_amount = stock.reduce(function(accumulator, currentValue){
					return accumulator + parseFloat(currentValue.total_value) ;
				}, total_amount);

				total_rows.push(<Grid item xs={6} key={unit + 'qty'} style={{paddingTop: 15}}>
					<Typography variant="h6" gutterBottom>
						Total Qty: {total_qty.toFixed(2)} {unit}
					</Typography>
				</Grid>);
				total_rows.push(<Grid item xs={6} key={unit + 'amount'} style={{paddingTop: 15}}>
					<Typography variant="h6">
						Total Value: {formatNumber(total_amount)}
					</Typography>
				</Grid>);

			});

			this.setState({items: data.stock, orignial_items: data.stock, chart_data, total_rows});
			this.props.setLinearLoading(false);
		})
		.catch(error => {
			console.log('failed to fetch Product Stocks');
			this.props.setLinearLoading(false);
			this.props.isLoading(false);
		})
	}

	handleWarehouseSelection = (e) => {
		this.setState({selected_warehouse: e.target.value}, () => {
		let items = [];
		if(this.state.selected_warehouse !== "-1"){
			items = this.state.items.filter((i) => i.warehouse_id === this.state.selected_warehouse)
		} else {
			items = this.state.items;
		}
		this.updateRows(items);
		});
	}

	sortBy = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
		order = 'asc';
		}

		let items = this.state.items;
		if(order === 'asc'){
		items.sort((a, b) => (a[orderBy] > b[orderBy]) ? 1 : -1);
		} else {
		items.sort((a, b) => (a[orderBy] < b[orderBy]) ? 1 : -1);
		}
		
		this.setState({ order, orderBy,  items}, () => {
				let rows = [];
				items.forEach((item, index) => {
				rows.push(<ProductDetails key={index} item={item} classes={this.props.classes}/>);
				});
				this.setState({current_rows: rows});
		});
	}

	filterCriteria = (e) =>{
        this.props.isLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value || "";
        }
        let filteredList = this.state.orignial_items.filter(data => ( this.state.selected_warehouse === '-1' ? true : data.warehouse_id === this.state.selected_warehouse ) && data.name.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = this.state.orignial_items;
        }
        this.setState({
            items: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Product'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
	}
	
	updateRows(data){
        let rows = [];
        data.forEach((item, index) => {
            rows.push(<ProductDetails key={index} item={item} classes={this.props.classes}/>);
          });
        this.setState({current_rows: rows});
    }

	render() {
		let {message, total_rows, transaction_count, todays_stats, yesterdays_stats, startdate, enddate, chart_data, inward_row, outward_row} = this.state;
		const { classes } = this.props;

		return (
		<div className="Home">
			<div className="lander">
				<div >
					<Grid container>
						<Grid item xs={12} lg={6}>
							<FormControl variant="outlined" className={classnames(classes.formControlLeft, classes.formControl)} style={{ float: "left"}}>
								<InputLabel
									ref={ref => {
									this.InputLabelRef = ref;
									}}
									htmlFor="outlined-warehouse-simple"
								>
									Warehouse
								</InputLabel>
								<Select
									value={this.state.selected_warehouse}
									onChange={this.handleWarehouseSelection}
									input={
									<OutlinedInput
										labelWidth={80}
										name="Warehouse"
										id="outlined-warehouse-simple"
									/>
									}
								>
									{this.state.warehouse_names}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} lg={6} style={{marginTop: 20}}>
							{total_rows.length > 0 &&
								<Paper style={{height:"100%"}}>
									<Grid container justify="space-around" spacing={2}>
										{total_rows}
									</Grid>
								</Paper>
							}
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={1}>
						<Grid item lg={4} xs={12}>
							<Paper className={classes.section}>
								<Doughnut height={130} data={chart_data}  legend={{display: false, position :"bottom"}}/>
							</Paper>
						</Grid>
						<Grid item lg={4} xs={12}>
							<Paper className={classes.section}>
								<Grid container direction="column">
									<Grid item xs={12} container sm justify="flex-end">
										<Typography variant="caption" color="textSecondary">{moment(startdate).format(format_display_fancy_notime_withDay)} - {moment(enddate).format(format_display_fancy_notime_withDay)}</Typography>
									</Grid>
									<Grid item xs={12} sm container direction="column" style={{paddingTop: 16}}>
										<Grid item xs>
											<Typography variant="h6">
												WEEK SO FAR
											</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="caption">
												Transactions: {transaction_count}
											</Typography>
										</Grid>
										<Grid item xs sm container direction="row" alignItems="flex-end" justify="space-evenly" style={{paddingTop: 16}}>
											<Grid item xs>
												{inward_row}
											</Grid>
											<Grid item>
												<Divider orientation="vertical"/>
											</Grid>
											<Grid item xs>
												{outward_row}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid item lg={4} xs={12}>
							<Paper className={classes.section}>
								<Grid container direction="row" style={{overflow : "auto"}}>
									<Grid item xs sm container direction="column" style={{paddingTop: 16}}>
										<Grid item xs>
											<Typography variant="button" style={{fontSize: 12}}>
												TODAY'S REVENUE
											</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="h6">
												{formatNumber(todays_stats.total_amount)}
											</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="caption">
												Transactions: {todays_stats.count}
											</Typography>
										</Grid>
									</Grid>
									<Grid item xs sm container direction="column" style={{paddingTop: 16}}>
										<Grid item xs>
											<Typography variant="button" style={{fontSize: 12}}>
												YESERDAY'S REVENUE
											</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="h6">
												{formatNumber(yesterdays_stats.total_amount)}
											</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="caption">
												Transactions: {yesterdays_stats.count}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<Paper className={classes.root} style={{width: "100%", marginTop: 10, paddingLeft: "5px", paddingRight: "5px"}}>
						<EnhancedTableToolbar title={"Stock"} exportReport={this.exportReport} refresh={this.refresh} onSearchTextChange={this.filterCriteria}/>
						<Table className={classes.table} size="small">
							<EnhancedTableHead
								order={this.state.order}
								orderBy={this.state.orderBy}
								onRequestSort={this.sortBy}
								rows ={this.state.columns} />
							<TableBody>
								{this.state.current_rows}
							</TableBody>
						</Table>
						<div className={classes.message}>
							{message}
						</div>
					</Paper>
				</div>
			</div>
		</div>
		);
	}
}

class ProductDetails extends Component {

  render(){
    const item = this.props.item;
    const classes = this.props.classes;

      return (
          <TableRow>
              <TableCell align="justify" scope="row" className={classes.tablecell}>
                  {item.name}
              </TableCell>
              <TableCell align="right" className={classes.tablecell}>
                  <Typography variant="subtitle2" component="h6" color={parseFloat(item.stock) < parseFloat(item.threshold) ? "secondary" : "primary"}>
                      {item.stock}   {item.product_unit ? item.product_unit : ""}
                  </Typography>
                  </TableCell>
              <TableCell align="center" className={classes.tablecell}>{item.warehouse}</TableCell>
			  <TableCell align="right" className={classes.tablecell}>{parseFloat(item.total_value).toFixed(2)}</TableCell>
			  <TableCell align="right" className={classes.tablecell}>{parseFloat(item.stock) > 0 ? parseFloat(item.total_value / item.stock).toFixed(2) : (0).toFixed(2)}</TableCell>
              <TableCell align="right" className={classes.tablecell} style={{paddingRight: 30}}>{item.threshold}</TableCell>
          </TableRow>
      );
  }
}

export default withStyles(styles)(Home);