import Config from '../Config';
import { RestService } from './RestService';

export const ItemService = {
    getItemList: async function(organisation){
        return RestService.get(`${Config.get_items}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    create: async function(organisation, headers, body){
        return RestService.post(`${Config.create_item}organisation=${organisation}&`, headers, body);
        // .then(response => response.json());
    },
    remove: async function(organisation, headers, id){
        return RestService.post(`${Config.remove_item}organisation=${organisation}&`, headers, {id: id});
        // .then(response => response.json());
    },
    getStock: async function(organisation, warehouse, product){
        return RestService.get(`${Config.get_stock}organisation=${organisation}&${warehouse === undefined ? '' : '&warehouses='+warehouse}${product === undefined ? '' : '&products='+product}`, {})
        .then(response => response.json());
    },
};