import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "../css/login.css";
import { UserService } from "../Services/UserService";
import { ROLES } from "../Roles";

export default class Login extends Component {
  constructor(props) {
    super(props);

    const user = JSON.stringify(localStorage.getItem('user'));
    const organisation = localStorage.getItem('organisation');

    this.state = {
        isLoading: false,
        mobile_no: "",
        organisation: user !== "null" && organisation !== null ? organisation : "",
        code: "",
        user: props.user
    };
  }

  componentDidMount(){
    this.props.setTitle('');
    const user = JSON.stringify(localStorage.getItem('user'));
    if(user == null) {
      this.props.history.push("/");
    }
  }

  validateForm() {
    return this.state.organisation.length > 2 && this.state.mobile_no.length > 9 && this.state.code.length > 3;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    if(this.state.organisation.length > 0){
      try {
        this.setState({ isLoading: true });
        localStorage.setItem('organisation', this.state.organisation);
        let body = { mobile_no : this.state.mobile_no,
                    code : this.state.code
                    };
        console.log(JSON.stringify(body));
        UserService.authenticate(this.state.organisation, body)
        .then((data) => {
            if ( data.id && data.status === '1' ) {
                this.props.showSnackBar("Logged in" , 'success');
                this.props.updateUser(data);
                console.log("Logged in");
                console.log(data);
                localStorage.setItem('user', JSON.stringify(data));
                this.props.userHasAuthenticated(true);
                if(data.roles.includes(ROLES.SUPER_ADMIN)){
                  this.props.history.push("/tools/manage/organisation/stats");
                } else {
                  this.props.history.push("/");
                }
                
            } else if(data.status === '0'){
              this.props.showSnackBar("Your account has been deactivated. Contact Admin" , 'error');
              this.setState({ isLoading: false });
            }
        })
        .catch((error) => {
          this.props.showSnackBar("Failed to Login. invalid credentials" , 'error');
            this.setState({ isLoading: false });
        });

        
      } catch (e) {
        alert(e.message);
        this.setState({ isLoading: false });
      }
    } else {
      this.props.showSnackBar("Organisation is required" , 'error');
    }
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="organisation" size="lg">
            <FormLabel>Organisation</FormLabel>
            <FormControl
              autoFocus
              size="lg"
              required
              type="text"
              value={this.state.organisation}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="mobile_no"  size="lg">
            <FormLabel>Mobile</FormLabel>
            <FormControl
              autoFocus
              size="lg"
              required
              type="text"
              value={this.state.mobile_no}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="code"  size="lg">
            <FormLabel>Code</FormLabel>
            <FormControl
              size="lg"
              ref="code"
              value={this.state.code}
              required
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
                block
                size="lg"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Login"
                loadingText="Logging in…"
                />
        </form>
      </div>
    );
  }
}