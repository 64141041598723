import React,{ Component } from "react";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import { getTimeInIST, format_display_fancy_notime } from "../Utils";


class ReportStockDetails extends Component {

    render(){
      const element = this.props.element;
      const classes = this.props.classes;
  
        return (
            <TableRow>
                <TableCell align="center" component="th" scope="row" className={classes.tablecell}>
                    {getTimeInIST(element.txn_date).format(format_display_fancy_notime)}
                </TableCell>
                <TableCell align="center" className={classes.tablecell}>
                    <Typography variant="subtitle2" component="h6">
                        {element.total_qty}
                    </Typography>
                </TableCell>
                {element.total_amount &&
                    <TableCell align="right" className={classes.tablecell}>
                        <Typography variant="subtitle2" component="h6">
                            {element.total_amount}
                        </Typography>
                    </TableCell>
                }
            </TableRow>
        );
    }
}

export default ReportStockDetails;