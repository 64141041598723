import React, { Component } from "react";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Bar } from 'react-chartjs-2';
import { WarehouseService } from "../../Services/WarehouseService";
import { Grid, Table, TableBody, Typography, Paper, withStyles } from "@material-ui/core";
import EnhancedTableHead from '../../components/EnhancedTableHead';
import ReportStockDetails from "../../components/ReportStockTable";
import { getTimeInIST, format_display_fancy_notime } from "../../Utils";

const styles = theme =>({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '12pt',
        padding: theme.spacing(2)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    }
  });

class PurchaseReport extends Component {

    constructor(props) {
      super(props);
      this.state = {
        startDate: moment().subtract(7, "days"),
        endDate: moment(),
        chart_data: {},
        columns : [
            { id: 'txn_date', numeric: false, disablePadding: true, label: 'Purchase Date', sortable: true },
            { id: 'total_qty', numeric: false, disablePadding: true, label: 'Total Qty' , sortable: true },
            { id: 'total_amount', numeric: true, disablePadding: false, label: 'Total Amount' , sortable: true },
          ],
        order: 'asc',
        orderBy: 'txn_date',
        current_rows: [],
        items : [],
        total_purchase_amt: 0
      };
      props.setTitle('Daily Purchase Report');
    }

    sortBy = (event, property) => {
        const orderBy = property;
        let order = 'desc';
    
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }
    
        let items = this.state.items;
        if(order === 'asc'){
          items.sort((a, b) => (a[orderBy] > b[orderBy]) ? 1 : -1);
        } else {
          items.sort((a, b) => (a[orderBy] < b[orderBy]) ? 1 : -1);
        }
        
        this.setState({ order, orderBy,  items}, () => {
                let rows = [];
                items.forEach((element, index) => {
                  rows.push(<ReportStockDetails key={index} element={element} classes={this.props.classes}/>);
                });
                this.setState({current_rows: rows});
        });
    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date,
        }, () => this.fetchData());
    }

    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date,
        }, () => this.fetchData());
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData(){
        this.props.isLoading(true);
        this.props.setLinearLoading(true);
        WarehouseService.getPurchaseReport(global.organisation, this.state.startDate, this.state.endDate)
        .then((data) => {
            let labels = [], puchases = [], backgroundColor = [];
            let rows = [];

            data.report.forEach((element, index) => {
                labels.push(getTimeInIST(element.txn_date).format(format_display_fancy_notime));
                puchases.push(element.total_qty);
                backgroundColor.push("#ff6383");
                rows.push(<ReportStockDetails key={index} element={element} classes={this.props.classes}/>);
            });
            this.props.isLoading(false);
            let chart_data = {
              labels: labels,
              datasets: [{
                label: "Puchases",
                fill: false,
                data: puchases,
                backgroundColor: backgroundColor
              }]
            };
            this.props.setLinearLoading(false);
            this.setState({items: data.report, chart_data, current_rows : rows});
        })
        .catch((error) => {
            console.log(error);
            this.props.setLinearLoading(false);
        })
        this.fetchTotalAmount();
    }

    fetchTotalAmount = () => {
        WarehouseService.getTotalAmount(global.organisation, this.state.startDate, this.state.endDate, "INWARD", undefined)
        .then((data) => {
            console.log(data);
            let purchase_txn = data.report.filter(r => r.txn_type === "INWARD");
            let total_purchase_amt = purchase_txn.length > 0 ? parseFloat(purchase_txn[0].total_amount) : 0;
            this.setState({total_purchase_amt});
        })
        .catch(() => {
            console.log("Error while fetching");
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Home">
                <div className={classes.container}>
                    <Grid container spacing={1}>
                        <Grid item lg={12} xs={12}>
                            <Paper className={classes.paper} style={{paddingLeft: "5px", paddingRight: "5px"}}>
                                <Grid container spacing={0}>
                                    <Grid item lg={6} xs={12}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                inputVariant="outlined"
                                                margin="normal"
                                                label="From"
                                                value={this.state.startDate}
                                                onChange={this.handleChangeStartDate}
                                                format='DD MMM YYYY'
                                                maxDate={this.state.endDate}
                                                variant="inline"
                                                className={classes.formControl}
                                                style={{float: "left", marginTop: "24px"}}
                                                />
                                            <DatePicker
                                                inputVariant="outlined"
                                                margin="normal"
                                                label="To"
                                                value={this.state.endDate}
                                                onChange={this.handleChangeEndDate}
                                                format='DD MMM YYYY'
                                                variant="inline"
                                                className={classes.formControl}
                                                style={{float: "left", marginTop: "24px"}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item lg={5} xs={8}>
                                        <Typography variant="subtitle2" style={{fontSize: "1.1rem", lineHeight: 1.75, paddingTop: 40}}>
                                            Total Purchase Amount
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} xs={4}>
                                        <Typography variant="subtitle1" style={{fontSize: "1.1rem", paddingTop: 40, paddingRight: 16}} color="secondary">
                                            {(this.state.total_purchase_amt).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Paper className={classes.paper} style={{width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
                                <Bar data={this.state.chart_data} />
                            </Paper>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Paper className={classes.paper} style={{maxHeight: "70vh", minHeight: "52vh", overflow: "hidden", overflowY: "scroll", width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
                                <div className={classes.tableWrapper}>
                                    <Table stickyHeader>
                                        <EnhancedTableHead
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onRequestSort={this.sortBy}
                                            rows ={this.state.columns} />
                                        <TableBody>
                                            {this.state.current_rows}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(PurchaseReport);