import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
// import { Navbar, NavItem, Nav, NavDropdown} from "react-bootstrap";
// import logo from './logo.svg';
import './css/App.css';
import Routes from "./routes";

import { css } from 'react-emotion';
import { CircleLoader } from 'react-spinners';

import moment from 'moment';
import {format_without_time} from './Utils';
import Config from './Config';
import {ROLES} from './Roles';
import { UserService } from './Services/UserService';

import MySnackbarContent from './components/SnackBar';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import StoreIcon from '@material-ui/icons/Store';
// import WorkIcon from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import PieChartIcon from '@material-ui/icons/PieChart';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import {
  withStyles,
  Snackbar,
  Dialog as DialogMUI,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  CssBaseline,
  IconButton,
  Fade,
  LinearProgress,
  Container,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import classNames from 'classnames';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const drawerWidth = 300;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionDesktop: {
    display: 'flex',
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  accountCircle: {
    marginRight: theme.spacing(3)
  }
});

class App extends Component {

  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem('user'));
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: user,
      loading : false,
      linear_loading: false,
      latitude: null,
      longitude: null,
      alertMessages: [],
      show: false,
      isAlertVisible: false,
      modules_enabled: {
        customer_app_options: false,
        inventory_management: false,
        b2c_module: true,
        b2b_module: true,
        promotions_module: true 
      },
      showStore: false,
      showPromotions: false,
      sb: {
          open : false,
          autohide: 6000,
      },
      openDialog: false,
      open: false,
      title: ""
    };

    this.updateUser = this.updateUser.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.addAlertMessage = this.addAlertMessage.bind(this);
    this.alertPopover = this.alertPopover.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setLinearLoading = (loading) => {
    this.setState({linear_loading: loading});
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  closeConfirmDialog = () => {
    this.setState({ openDialog: false });
  }

  handleSBClose = () => {
      this.setState(prevState => ({
          sb: {
              ...prevState.sb,
              open: false,
              message : '',
              varient: 'info'
          }
      }));
  }

  showSnackBar = (message, varient) => {
    this.setState(prevState => ({
        sb: {
            ...prevState.sb,
            open: true,
            message: message,
            varient: varient
        }
    }));
  }

  showConfirmDialog = (dialog_message, dialog_title, confirmAction, cancel_title = "Cancel", accept_title = "Confirm") => {
    this.setState({
      dialog_message: dialog_message, 
      dialog_title: dialog_title,
      openDialog: true, 
      confirmAction: confirmAction,
      cancel_title: cancel_title,
      accept_title: accept_title
    });
  }

  setTitle = (message) => {
    this.setState({
      title: message
    });
  }

  alertPopover(){
    if(this.state.isAlertVisible){
      this.handleShow();
    }
  }

  handleClose() {
      this.setState({ show: false });
  }

  handleShow() {
      this.setState({ show: true });
  }

  componentDidMount() {
    if(this.state.user == null) {
      this.props.history.push("/login");
    }
    try {
      if (this.state.user !== null) {
        this.userHasAuthenticated(true);
      }
      if(this.state.user){
        let body = { mobile_no : this.state.user.mobile_no,
          code : this.state.user.code
        };
        UserService.authenticate(localStorage.getItem('organisation'), body)
        .then((data) => {
            if ( data.id && data.status === '1') {
                this.updateUser(data);
                localStorage.setItem('user', JSON.stringify(data));
                this.userHasAuthenticated(true);
                this.props.history.push(this.props.location.pathname);

                var dateToCheck = moment().set({hour:Config.defaultAlerts.import.hr,minute:Config.defaultAlerts.import.min,second:0,millisecond:0});
                if(moment().isAfter(dateToCheck)){
                  this.setState({alertMessages: [...this.state.alertMessages, <div key="import" style={{cursor: "pointer"}} onClick={()=> { this.handleClose(); this.props.history.push("/import") }}>Dont <strong>Forget</strong> to import schedules for {moment().format(format_without_time)}</div>]});
                }

                setTimeout(() =>{
                  this.setState({isAlertVisible: this.state.alertMessages.length > 0})
                }, 2000);
                setInterval(() =>{
                  this.setState({isAlertVisible: this.state.alertMessages.length > 0})
                }, 60000);
            } else {
                this.props.history.push("/login");
            }
        })
        .catch((error) => {
            this.props.history.push("/login");
        });
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    if (navigator.geolocation) {
        this.updateLocation();
    } else {
      // no can do
    }
  
    this.setState({ isAuthenticating: false });
  }

  updateLocation(position){
    navigator.geolocation.getCurrentPosition((position) =>{
      this.setState({latitude: position.coords.latitude, longitude: position.coords.longitude});
    });
  }

  addAlertMessage(alertMessages){
    this.setState(prevState => ({
      alertMessages: [...prevState.alertMessages, alertMessages]
    }));
  }

  isLoading(loading){
    this.setState({loading: loading});
  }
  
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
    global.organisation = localStorage.getItem('organisation');
  }

  handleLogout = event => {
    this.userHasAuthenticated(false);
    localStorage.removeItem('user');
    localStorage.removeItem('organisation');
    this.props.history.push("/login");
  }

  updateUser(user){
      this.setState({user: user});
  }

  render() {

    const { classes } = this.props;
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user,
      updateUser: this.updateUser,
      isLoading: this.isLoading,
      loading: this.state.loading,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      updateLocation: this.updateLocation,
      addAlertMessage: this.addAlertMessage,
      modules_enabled: this.state.modules_enabled,
      showStore: this.state.showStore,
      showPromotions: this.state.showPromotions,
      showSnackBar: this.showSnackBar,  
      showConfirmDialog: this.showConfirmDialog,
      closeConfirmDialog: this.closeConfirmDialog,
      setTitle: this.setTitle,
      setLinearLoading: this.setLinearLoading
    };

    return (
      !this.state.isAuthenticating &&
        <div className="App container">
          <div className={classes.root}>
            <Fade
                in={this.state.linear_loading}
                style={{
                    transitionDelay: this.state.linear_loading ? '50ms' : '0ms',
                }}
                unmountOnExit
                >
                <LinearProgress id="test" style={{height: 5, position: "fixed", top: 64, width: "95.4%"}} color="secondary"/>
            </Fade>
            <CssBaseline />
            <AppBar position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: this.state.open,
            })}
            >
              <Toolbar disableGutters={!this.state.open}>
              { this.state.isAuthenticated  &&
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(classes.menuButton, {
                    [classes.hide]: this.state.open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              }
              { !this.state.open && 
                <Typography variant="h6" color="inherit" className="grow">
                  {this.state.title}
                </Typography>
              }
                { this.state.isAuthenticated &&
                  <Button color="inherit" className={classes.sectionMobile} style={{position: "absolute", right: 130}}>{`${this.state.user.name} [ Organisation: ${localStorage.getItem('organisation')} ]`}</Button>
                }
                {this.state.isAuthenticated ? 
                  <Button color="inherit" style={{position: "absolute", right: 20}}  onClick={this.handleLogout}>LogOut</Button>
                :
                  <Button color="inherit" style={{position: "absolute", right: 20, color: "white"}}><Link to="/login" style={{color: "inherit", textDecoration: "none"}}>Login</Link></Button>
                }
              </Toolbar>
            </AppBar>
            { this.state.isAuthenticated  &&
              <Drawer
                variant="temporary"
                anchor="left"
                className={classNames(classes.drawer, {
                  [classes.drawerOpen]: this.state.open,
                  [classes.drawerClose]: !this.state.open,
                })}
                classes={{
                  paper: classNames({
                    [classes.drawerOpen]: this.state.open,
                    [classes.drawerClose]: !this.state.open,
                  }),
                }}
                open={this.state.open}
                onClick={this.handleDrawerClose}
              >
                <div className={classes.toolbar}>
                  <IconButton onClick={this.handleDrawerClose}>
  {/* <AccountCircleIcon className={classes.accountCircle}/> */}
                    { this.state.isAuthenticated &&
                      <section className={classes.sectionDesktop}>{this.state.user.name}</section>      
                    }
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <Divider />
                {(this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.EMPLOYEE))  &&
                    <Link to="/" style={{ textDecoration: 'none' }}>
                      <ListItem button key="Dashboard">
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Dashboard" />
                      </ListItem>
                    </Link>
                }
                {/* { this.state.user.roles.includes(ROLES.ADMIN) &&
                    <Link to="/projects" style={{ textDecoration: 'none' }}>
                      <ListItem button key="Projects">
                        <ListItemIcon><AssignmentIcon /></ListItemIcon>
                        <ListItemText primary="Projects" />
                      </ListItem>
                    </Link>
                } */}
                { (this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.EMPLOYEE))  &&
                    <Link to="/products" style={{ textDecoration: 'none' }}>
                      <ListItem button key="Products">
                        <ListItemIcon><EventSeatIcon /></ListItemIcon>
                        <ListItemText primary="Products" />
                      </ListItem>
                    </Link>
                }
                {this.state.user.roles.includes(ROLES.ADMIN) &&
                  <div>
                    <Divider />
                    <Typography variant="subtitle1" align="left" className="sub-menu">
                      Accounts
                    </Typography>
                      <Link to="/accounts" style={{ textDecoration: 'none' }}>
                        <ListItem button key="Manage Accounts">
                          <ListItemIcon><PeopleIcon /></ListItemIcon>
                          <ListItemText primary="Manage Accounts" />
                        </ListItem>
                      </Link>
                      {/* <Link to="/contractors" style={{ textDecoration: 'none' }}>
                        <ListItem button key="Contractors">
                          <ListItemIcon><PeopleIcon /></ListItemIcon>
                          <ListItemText primary="Contractors" />
                        </ListItem>
                      </Link> */}
                  </div>
                }
                {  (this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.EMPLOYEE)) &&
                  <div>
                    <Divider />
                    <Typography variant="subtitle1" align="left" className="sub-menu">
                      Inventory
                    </Typography>
                      <Link to="/transactions/view" style={{ textDecoration: 'none' }}>
                        <ListItem button key="View Transactions">
                          <ListItemIcon><DescriptionIcon /></ListItemIcon>
                          <ListItemText primary="View Transactions" />
                        </ListItem>
                      </Link>
                      {/* <Link to="/contractors" style={{ textDecoration: 'none' }}>
                        <ListItem button key="Contractors">
                          <ListItemIcon><PeopleIcon /></ListItemIcon>
                          <ListItemText primary="Contractors" />
                        </ListItem>
                      </Link> */}
                  </div>
                }
                {this.state.user.roles.includes(ROLES.ADMIN) &&
                  <div>
                  <Divider />
                  <Typography variant="subtitle1" align="left" className="sub-menu">
                    Reports
                  </Typography>
                    { (this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/report/daily/sales" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Daily Sales">
                            <ListItemIcon><PieChartIcon /></ListItemIcon>
                            <ListItemText primary="Daily Sales" />
                          </ListItem>
                        </Link>
                    }
                    { (this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/report/daily/puchases" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Daily Purchases">
                            <ListItemIcon><PieChartIcon /></ListItemIcon>
                            <ListItemText primary="Daily Puchases" />
                          </ListItem>
                        </Link>
                    }
                    { (this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/report/product/stats" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Productwise Sales & Purchase Report">
                            <ListItemIcon><PieChartIcon /></ListItemIcon>
                            <ListItemText primary="Productwise Sales & Purchase Report" />
                          </ListItem>
                        </Link>
                    }
                    {/* {  this.state.user.roles.includes(ROLES.ADMIN) &&
                        <Link to="/report/summary" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Summary">
                            <ListItemIcon><AssessmentIcon /></ListItemIcon>
                            <ListItemText primary="Summary" />
                          </ListItem>
                        </Link>
                    } */}
                  </div>
                }
                {/* { (this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.STORE_MANAGER)) &&
                  <div>
                    <Divider />
                    <Typography variant="subtitle1" align="left" className="sub-menu">
                      Transactions
                    </Typography>
                      <Link to="/transaction/store" style={{ textDecoration: 'none' }}>
                        <ListItem button key="Store Transactions">
                          <ListItemIcon><StoreIcon /></ListItemIcon>
                          <ListItemText primary="Store Transactions" />
                        </ListItem>
                      </Link>
                      <Link to="/transaction/jobstatus" style={{ textDecoration: 'none' }}>
                        <ListItem button key="Job Status">
                          <ListItemIcon><WorkIcon /></ListItemIcon>
                          <ListItemText primary="Job Status" />
                        </ListItem>
                      </Link>
                  </div> */}
                {/* } */}
                {  (this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.SUPER_ADMIN)) &&
                  <div>
                    <Divider />
                    <Typography variant="subtitle1" align="left" className="sub-menu">
                      Tools
                    </Typography>
                        {  (this.state.user.roles.includes(ROLES.ADMIN)) &&
                            <Link to="/tools/warehouse" style={{ textDecoration: 'none' }}>
                              <ListItem button key="Manage Warehouses">
                                <ListItemIcon><StoreIcon /></ListItemIcon>
                                <ListItemText primary="Manage Warehouses" />
                              </ListItem>
                            </Link>
                        }
                        {  (this.state.user.roles.includes(ROLES.ADMIN)) && (this.state.user.configuration && this.state.user.configuration.template_enabled) &&
                            <Link to="/tools/templates" style={{ textDecoration: 'none' }}>
                              <ListItem button key="Manage Templates">
                                <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                                <ListItemText primary="Manage Templates" />
                              </ListItem>
                            </Link>
                        }
                        {  (this.state.user.roles.includes(ROLES.SUPER_ADMIN)) &&
                            <Link to="/tools/manage/organisation/stats" style={{ textDecoration: 'none' }}>
                              <ListItem button key="Organisation Stats">
                                <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
                                <ListItemText primary="Organisation Stats" />
                              </ListItem>
                            </Link>
                        }
                        {  (this.state.user.roles.includes(ROLES.SUPER_ADMIN)) &&
                            <Link to="/tools/manage/organisation" style={{ textDecoration: 'none' }}>
                              <ListItem button key="Manage Organisation Configuration">
                                <ListItemIcon><SettingsIcon /></ListItemIcon>
                                <ListItemText primary="Manage Organisation Configuration" />
                              </ListItem>
                            </Link>
                        }
                        {  (this.state.user.roles.includes(ROLES.SUPER_ADMIN)) &&
                            <Link to="/tools/manage/users" style={{ textDecoration: 'none' }}>
                              <ListItem button key="Manage Organisation Users">
                                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                <ListItemText primary="Manage Organisation Users" />
                              </ListItem>
                            </Link>
                        }
                  </div>
                }
                {/* <Divider />
                <Link to="/help" style={{ textDecoration: 'none' }}>
                  <ListItem button key="Help">
                    <ListItemIcon><HelpIcon /></ListItemIcon>
                    <ListItemText primary="Help" />
                  </ListItem>
                </Link> */}
                <Divider />
                <Container maxWidth="sm" style={{position: "absolute", bottom: 0}}>
                  <Typography variant="body1" style={{textAlign: 'center'}}>
                    Version: {global.appVersion} 
                    <React.Fragment>
                      <br/>Powered by Sysbrew
                    </React.Fragment>
                    
                  </Typography>
                </Container>
              </Drawer>
            }
          </div>
          <Routes childProps={childProps} style={{width: "50%"}}/>
          <div className='sweet-loading'>
            <CircleLoader
              className={override}
              sizeUnit={"px"}
              size={50}
              color={'#123abc'}
              loading={this.state.loading}
            />
          </div>
          <Snackbar
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              open={this.state.sb.open}
              autoHideDuration={this.state.sb.autohide}
              onClose={this.handleSBClose}>
                  <MySnackbarContent
                      onClose={this.handleSBClose}
                      variant={this.state.sb.varient}
                      message={this.state.sb.message}
                  />
          </Snackbar>
          <DialogMUI
              disableEnforceFocus
              open={this.state.openDialog}
              onClose={this.closeConfirmDialog}
              fullWidth={true}
              maxWidth='sm'
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
                  {this.state.dialog_title ? <DialogTitle id="alert-dialog-title">{this.state.dialog_title}</DialogTitle> : ''}
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          {this.state.dialog_message}
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                        <Button onClick={this.closeConfirmDialog} color="primary">
                          {this.state.confirmAction ? this.state.cancel_title : "Ok"}
                        </Button>
                        {
                          this.state.confirmAction &&
                          <Button onClick={this.state.confirmAction} color="primary" autoFocus>
                            {this.state.accept_title}
                          </Button>
                        }
                  </DialogActions>
          </DialogMUI>
        </div>
        
    );
  }
}

export default withStyles(styles)(withRouter(App));
