import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, Tooltip, TableSortLabel, Typography } from '@material-ui/core';

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    };
  
    render() {
      const { rows, order, orderBy } = this.props;
  
      return (
        <TableHead>
          <TableRow>
            {rows.map(
              row => (
                <TableCell
                  style={{fontSize: "16px", backgroundColor: "#fff", paddingBottom: 8, zIndex: 1}}
                  key={row.id}
                  align={row.numeric ? 'right' : 'center'}
                  padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false} 
                  variant='head'>
                  { row.sortable ? 
                        <Tooltip
                            title="Sort"
                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300} >
                            <TableSortLabel
                              active={orderBy === row.id}
                              direction={order}
                              onClick={this.createSortHandler(row.id)} >
                              <Typography variant="subtitle1">{row.label}</Typography>
                            </TableSortLabel>
                        </Tooltip>
                        : <Typography variant="subtitle1">{row.label}</Typography>
                  }
                </TableCell>
              ),
              this,
            )}
          </TableRow>
        </TableHead>
      );
    }
  }

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired
};

export default EnhancedTableHead;