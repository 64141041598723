import React, { Component } from "react";
import "../css/item.css";
import { Form} from 'react-bootstrap';
import {toInt} from '../Utils';
import XLSX from 'xlsx';
import { ItemService } from "../Services/ItemService";
import { UnitService } from "../Services/UnitService";
import AddIcon from '@material-ui/icons/Add';
import {
    TableRow,
    Tooltip,
    Icon,
    IconButton,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Paper,
    Typography,
    withStyles,
    Button,
    TextField,
    Modal,
    Divider,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
} from '@material-ui/core';
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";

function getModalStyle() {
    const top = 50 ;
    const left = 50 ;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    table: {
        display: 'inline-block',  
	  	[theme.breakpoints.up('md')]: {
			minWidth: 500,
			display: 'inline-table',  
		},
	  	overflow: 'auto'
    },
  });

class Item extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        show: false,
        new_item: { name: "", unit: "", unit_price: 0, sgst: 0, cgst: 0, cess: 0, threshold: 0},
        sheet: "Items",
        filename: "Product List",
        unit_name_rows: [],
        searchText: ''
      }
      props.setTitle("Products");

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.edit = this.edit.bind(this);
      this.updateRows = this.updateRows.bind(this);
      this.export = this.export.bind(this);
    }

    confirmSave = () => {
        this.handleClose();
        this.props.closeConfirmDialog();
        this.createItemAPI(this.state.new_item);
    }

    createModifyItem = (e) => {
        e.preventDefault();
        if(this.state.new_item.name && this.state.new_item.unit && this.state.new_item.sgst >= 0 && this.state.new_item.cgst >= 0 && this.state.new_item.cess >= 0 && this.state.new_item.threshold >= 0 ){
            this.props.showConfirmDialog("Confirm?", undefined, this.confirmSave);
        } else {
            this.props.showSnackBar('Please fill all the details and continue', 'error');
        }
    }

    createItemAPI(new_item){
        new_item.threshold = toInt(new_item.threshold);
        new_item.display_name = new_item.name;
        let body = new_item;
        this.props.isLoading(true);
        let headers = {user_id : this.props.user.id };
        ItemService.create(global.organisation, headers, body)
        .then((response) => {
            if(response.status === 409){
                this.props.showSnackBar(`${new_item.name} already exists.`, 'error');
            } else {
                this.setState({
                    new_item: { name: "", unit: "", unit_price: 0, sgst: 0, cgst: 0, cess: 0, threshold: 0}
                });
                this.fetchData();
                if(this.state.show) this.handleClose();
                this.props.isLoading(false);
                this.props.showSnackBar(`${new_item.id ? 'Saved' : 'Created'} Product: ${new_item.name}`, 'success');
            }
        })
        .catch(() => {
            this.props.isLoading(false);
            this.props.showConfirmDialog(`Failed to create product : ${new_item.name}. Please try again.`, undefined, undefined);
        });
        
    }

    edit(e, item){
        e.preventDefault();
        if(item){
            this.setState({new_item: {id: item.id, name: item.name, unit: item.unit, unit_price:0, sgst:item.sgst, cgst:item.cgst, cess: item.cess, threshold: item.threshold}}, function () {
                this.handleShow();
            });
        } else {
            this.setState({show: true});
        }
    }
 
    componentDidMount(){
        this.fetchData();
        this.fetchUnits();
    }

    fetchUnits = () => {
        UnitService.getUnits(global.organisation)
        .then((data) => {
            console.log(data);
            let unit_name_rows = data.units.map((w) => (
                <MenuItem key={w.id} value={w.id}>{`${w.description} [ ${w.id}]`}</MenuItem>
            ));
            this.setState({unit_name_rows});
        })
        .catch((error) => console.log(error));
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_item: {name: "", unit: "", unit_price: 0, sgst: 0, cgst: 0, cess: 0, threshold: 0}
        });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleChange = (e) => {
        e.preventDefault();
        let change = this.state.new_item;
        change[e.target.name] = e.target.value;
        this.setState(change);        
    }


    fetchData(){
        this.props.isLoading(true);
        ItemService.getItemList(global.organisation)
        .then((data) => {
            console.log(data);
            this.setState({data: data.products,  originalData: data.products});
            this.filterCriteria();
            this.props.isLoading(false);
        })
        .catch(error => {
            console.error(error);
            this.props.isLoading(false);
        });
    }

    updateRows(data){
        let rows = [];
        data.forEach((item) => {
            rows.push(<ItemDetails key={item.id} item={item} remove={this.remove} edit={this.edit}/>);
          });
        this.setState({rows: rows});
    }

    export(e){
        e.preventDefault();
        let exportData = [["SL.NO", "NAME", "UNIT", "SGST", "CGST", "CESS", "THRESHOLD"]];
        this.props.isLoading(true);

        this.state.originalData.forEach((prod, index) => {
            let prodArray = [index + 1, prod.name, prod.unit, prod.sgst, prod.cgst, prod.cess, prod.threshold];
            exportData.push(prodArray)
        });

        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(exportData);
        XLSX.utils.book_append_sheet(wb, wsAll, this.state.sheet.substring(0, 30));
        XLSX.writeFile(wb, this.state.filename + ".xlsx");

        this.props.isLoading(false);
    }

    filterCriteria = (e) =>{
        this.props.isLoading(true);
        let searchText = this.state.searchText;
        if(e){
            e.preventDefault();
            searchText = e.target.value || "";
            this.setState({searchText})
        }
        let filteredList = this.state.originalData.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Product'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    remove = (e, id) => {
        e.preventDefault();
        if(id) {
            this.props.showConfirmDialog("This will not effect any of the existing Transactions.", "Confirm removal of Product?", () => {
                let headers = {user_id : this.props.user.id };
                ItemService.remove(global.organisation, headers, id)
                .then(() =>{
                    this.refs.inputText.value = "";
                    this.fetchData();
                    this.props.closeConfirmDialog();
                    this.props.showSnackBar(`Removed Product`, 'success');
                })
                .catch((error) => {
                    console.log(error);
                    this.props.showSnackBar(`Failed to remove Product. Please try again.`, 'error');
                });
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Home">
                {/* <h3>Products <Badge>{this.state.data.length}</Badge></h3> */}
                <div style={{marginTop: 24}}>
                    <Paper className={classes.root} style={{paddingTop : '15px', paddingLeft: "5px", paddingRight: "5px", marginTop: '10px'}}>
                        {/* <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form> */}
                        <EnhancedTableToolbar title={"Products: "+ this.state.data.length} onSearchTextChange={this.filterCriteria}/>
                        <Table className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Unit</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">SGST</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">CGST</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">CESS</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle1">Threshold</Typography></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
                <Modal 
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.show} 
                    onClose={this.handleClose}>
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="h6" id="modal-title">
                            { this.state.new_item.id ? 'Modify Product' : 'Create Product' }
                        </Typography>
                        <Divider />
                        {this.itemForm(this.createModifyItem, "Save")}
                    </div>
                </Modal>
                <Tooltip title="Add Product" >
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        );
    }

    itemForm = (submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="name"
                label="Name"
                placeholder="Name"
                name="name"
                defaultValue={this.state.new_item.name}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <FormControl variant="outlined" fullWidth style={{marginLeft: 8, marginRight: 8}}>
                <InputLabel
                    htmlFor="outlined-unit-simple"
                >
                    Unit
                </InputLabel>
                <Select
                    value={this.state.new_item.unit}
                    onChange={this.handleChange}
                    input={
                    <OutlinedInput
                        labelWidth={30}
                        name="unit"
                        id="outlined-unit-simple"
                    />
                    }
                >
                    {this.state.unit_name_rows}
                </Select>
            </FormControl>
            {/* <TextField
                id="unit"
                label="Unit"
                defaultValue={this.state.new_item.unit}
                placeholder="Unit"
                name="unit"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                /> */}
            <TextField
                id="sgst"
                className={this.props.classes.textField}
                variant="outlined"
                label="SGST"
                margin="normal"
                defaultValue={this.state.new_item.sgst}
                name="sgst"
                onChange={this.handleChange}
                pattern="[0-9]*"
                type="number"
                InputProps={{
                    inputProps: { min: 0, max: 100 }
                }}
                required
                />
            <TextField
                id="cgst"
                className={this.props.classes.textField}
                variant="outlined"
                label="CGST"
                margin="normal"
                defaultValue={this.state.new_item.cgst}
                name="cgst"
                onChange={this.handleChange}
                pattern="[0-9]*"
                type="number"
                InputProps={{
                    inputProps: { min: 0, max: 100 }
                }}
                required
                />
            <TextField
                id="cess"
                className={this.props.classes.textField}
                variant="outlined"
                label="CESS"
                margin="normal"
                defaultValue={this.state.new_item.cess}
                name="cess"
                onChange={this.handleChange}
                pattern="[0-9]*"
                type="number"
                InputProps={{
                    inputProps: { max: 100 }
                }}
                required
                />
            <TextField
                id="threshold"
                className={this.props.classes.textField}
                variant="outlined"
                label="Threshold"
                margin="normal"
                defaultValue={this.state.new_item.threshold}
                name="threshold"
                onChange={this.handleChange}
                pattern="[0-9]*"
                type="number"
                InputProps={{
                    inputProps: { min: 0 }
                }}
                required
                />
            <br/>
            <div style={{marginTop: "10px", marginLeft: "8px"}}>
                <Button variant="outlined" size="medium" color="primary" type="submit" onClick={submitFunction} style={{marginRight: "10px"}}>{buttonText}</Button>
                {!this.state.show ? (<Button type="submit" variant="contained" size="medium" color="primary" onClick={this.export} style={{marginRight: "10px"}}>Export</Button>): ""}
                {this.state.show ? (<Button variant="outlined" size="medium" color="primary" onClick={this.handleClose}>Close</Button>): ""}
            </div>
        </Form>
    )

}

class ItemDetails extends Component {

    render(){
      const item = this.props.item;
      return (
        <TableRow key={item.id}>
            <TableCell component="th" scope="row" padding="none" style={{paddingLeft: 16}}>
                {item.name}
            </TableCell>
            <TableCell align="center" padding="none">{item.unit}</TableCell>
            <TableCell align="center" padding="none">{item.sgst}</TableCell>
            <TableCell align="center" padding="none">{item.cgst}</TableCell>
            <TableCell align="center" padding="none">{item.cess}</TableCell>
            <TableCell align="center" padding="none">{item.threshold}</TableCell>
            <TableCell align="right" padding="none">
                <Tooltip title="Edit">
                    <IconButton aria-label="Edit" onClick={(e) => this.props.edit(e, item)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Remove Product">
                    <IconButton aria-label="Remove Product" onClick={(e) => this.props.remove(e, item.id)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
                
            </TableCell>
        </TableRow>
      );
    }
}

export default withStyles(styles)(Item);