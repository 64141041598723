import Config from '../Config';

export const RestService = {
    get: async function(url, headers) {
       headers["Content-Type"] = "application/json";
       headers["device_id"] = "test";
       headers["sysbrew-x-token"] = Config.token;
       headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
        return fetch(url, {
            method: "GET",
            headers: headers
        });
    },
    delete: async function(url, headers) {
        headers["Content-Type"] = "application/json";
        headers["device_id"] = "test";
        headers["sysbrew-x-token"] = Config.token;
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "DELETE",
             headers: headers
         });
    },
    post:  async function(url, headers, body) {
        headers["Content-Type"] = "application/json";
        headers["device_id"] = "test";
        headers["sysbrew-x-token"] = Config.token;
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
         return fetch(url, {
             method: "POST",
             headers: headers,
             body: body ? JSON.stringify(body) : null
         });
     }
};
