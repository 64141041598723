import React, { Component } from "react";
import { OrganisationService } from "../Services/OrganisationService";

import {
    Paper,
    Typography,
    withStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    table: {
        // overflowX: 'auto',
        // display: 'block',
        minWidth: 500,
    },
  });

class Organisation extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: []
      }
    }

    componentWillMount(){
        this.props.setTitle("Organisations");
    }
 
    componentDidMount(){
        this.fetchData();
    }

    handleChange = (organisation, name) => event => {
        organisation[name] = event.target.checked;
        let body = organisation;
        let headers = {user_id : this.props.user.id };
        OrganisationService.updateOrganisation(global.organisation, headers, body)
        .then((response) => {
            if(response.status === 409){
                this.props.showSnackBar(`${organisation.id} already exists.`, 'error');
            } else {
                this.fetchData();
                if(this.state.show) this.handleClose();
                this.props.showSnackBar(`Saved Organisation: ${organisation.id}`, 'success');
            }
            this.props.isLoading(false);
        })
        .catch(() => {
            this.props.isLoading(false);
            this.props.showConfirmDialog(`Failed to update organisation : ${organisation.id}. Please try again.`, undefined, undefined);
        });
    }


    fetchData(){
        this.props.isLoading(true);
        OrganisationService.getOrganisations(global.organisation)
        .then((data) => {
            console.log(data);
            this.setState({data: data.organisations,  originalData: data.organisations});
            this.filterCriteria();
            this.props.isLoading(false);
        })
        .catch(error => {
            console.error(error);
            this.props.isLoading(false);
        });
    }

    updateRows = (data) => {
        let rows = [];
        data.forEach((organisation) => {
            rows.push(<OrganisationDetails key={organisation.id} organisation={organisation} handleChange={this.handleChange}/>);
          });
        this.setState({rows: rows});
    }

    filterCriteria = (e) => {
        this.props.isLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText =  e.target.value;
        }
        
        let filteredList = this.state.originalData.filter(data => data.id.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Organisation'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    render() {
        const { classes } = this.props;
        const { data, message } = this.state;
        return (
            <div className="Home">
                {/* <h3 style={{marginBottom: '5px'}}>Organisations <Badge>{this.state.data.length}</Badge></h3> */}
                <div className="container">
                    <Paper className={classes.root} style={{width: "100%", paddingTop : '15px', paddingLeft: "5px", paddingRight: "5px", marginTop: '10px'}}>
                        {/* <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form> */}
                        <EnhancedTableToolbar title={"Organisations:"} subtitle={data.length} onSearchTextChange={this.filterCriteria}/>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Weight Module</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Internal Module</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Daily Stock Report Emails Module</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Template Module</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                        {
                            message && <p style={{color:"red", paddingTop: 16, fontSize: "14pt", paddingLeft:"20px", width: "100%", textAlign: "center"}}>{message}</p>
                        }
                    </Paper>
                </div>
            </div>
        );
    }

}


class OrganisationDetails extends Component {

    render(){
      const organisation = this.props.organisation;
      return (
        <TableRow key={organisation.id}>
            <TableCell component="th" scope="row">
                {organisation.id}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                <FormControlLabel 
                control={
                    <Switch
                        checked={organisation.weight_module}
                        value="weight_module"
                        color="primary"
                        onChange={this.props.handleChange(organisation, 'weight_module')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    } 
                label={organisation.weight_module ? "Enabled" : "Disabled"} />
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                <FormControlLabel 
                control={
                    <Switch
                        checked={organisation.internal_module}
                        value="internal_module"
                        color="primary"
                        onChange={this.props.handleChange(organisation, 'internal_module')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    } 
                label={organisation.internal_module ? "Enabled" : "Disabled"} />
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                <FormControlLabel 
                control={
                    <Switch
                        checked={organisation.email_notification_module}
                        value="email_notification_module"
                        color="primary"
                        onChange={this.props.handleChange(organisation, 'email_notification_module')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    } 
                label={organisation.email_notification_module ? "Enabled" : "Disabled"} />
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>
                <FormControlLabel 
                control={
                    <Switch
                        checked={organisation.template_enabled}
                        value="template_enabled"
                        color="primary"
                        onChange={this.props.handleChange(organisation, 'template_enabled')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    } 
                label={organisation.template_enabled ? "Enabled" : "Disabled"} />
            </TableCell>
        </TableRow>
      );
    }
}

export default withStyles(styles)(Organisation);