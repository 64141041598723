import React, { Component } from "react";
import {
    withStyles,
    Slide,
    Avatar,
    Paper,
    TableRow,
    TableCell,
    Typography,
    InputLabel,
    FormControl,
    ListItem,
    ListItemSecondaryAction,
    Select,
    DialogActions,
    OutlinedInput,
    Table,
    TablePagination,
    TableBody,
    DialogContent,
    DialogContentText,
    TextField,
    Fab,
    Button,
    IconButton,
    MenuItem,
    ListItemText,
    Grid,
    AppBar,
    Toolbar,
    Divider,
    TableHead,
    Stepper,
    StepLabel,
    Step,
    List,
    Tooltip,
    Dialog as DialogMUI,
    Icon,
    Popover,
} from "@material-ui/core";
import moment from "moment";
import {red, blue} from '@material-ui/core/colors';
import { TransactionService } from "../Services/TransactionService";
import { toInt, format_without_time, getTimeInIST, format_display_date, formatNumber, format, isInt, format_export } from "../Utils";
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import AddIcon from '@material-ui/icons/Add';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import EnhancedTableHead from "../components/EnhancedTableHead";
import SelectDropdown from "../components/SelectDropdown";
import { RowCountOptions } from "../Config";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { ProductService } from "../Services/ProductService";
// import AutoCompleteSelection from "../components/AutoCompleteSelection";
import { WarehouseService } from "../Services/WarehouseService";
import EnhancedTableToolbar from '../components/EnhancedTableToolbar';
import XLSX from 'xlsx';
import ItemSelection from "../components/ItemSelection";

const styles = theme =>({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '12pt',
        padding: theme.spacing(2)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            minWidth: 300
        },
    },
    row: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.default,
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(3),
        height: theme.spacing(7),
        float: 'left',
        marginLeft: theme.spacing(2),
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    typography: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Transactions extends Component {

    constructor(props){
        super(props);
        this.state = {
            current_rows: [],
            selected_products: undefined,
            selected_warehouses: "-1",
            startdate: moment().subtract(7, "days"),
            enddate: moment(),
            selected_txn_type: "-1",
            numItemsPerPage: 10,
            currentPageNumber: 0,
            totalItems: 0,
            totalPages: 0,
            show: false,
            columns : [
                // { id: 'avatar', numeric: false, disablePadding: true, label: '', sortable: false },
                { id: 'index', numeric: false, disablePadding: true, label: 'Sl. No', sortable: false },
                { id: 'txn_date', numeric: false, disablePadding: true, label: 'Transaction Date', sortable: false },
                { id: 'txn_type', numeric: false, disablePadding: true, label: 'Type', sortable: false  },
                { id: 'warehouse', numeric: false, disablePadding: true, label: 'Warehouse', sortable: false  },
                { id: 'items', numeric: false, disablePadding: true, label: 'Items', sortable: false  },
                { id: 'qty', numeric: false, disablePadding: true, label: 'Quantity', sortable: false  },
                { id: 'actions', numeric: false, disablePadding: true, label: '', sortable: false  },
            ],
            message : '',
            order: "desc",
            orderBy: "txn_date",
            master_products: [],
            master_warehouses: [],
            master_txn_types: [{id: 'INWARD',name: 'INWARD'},{id: 'OUTWARD', name: 'OUTWARD'}],
            product_name_rows: [],
            showDetails: false,
            txn_details: {},
            txn_details_rows: [],


            // Create Transaction
            activeStep: 0,
            steps: this.getSteps(),
            new_txn: {},
            add_item_rows: [],
            product_stock_rows: [],

            openSummary: false,
            anchorEl: null
        };

        props.setTitle("View Transactions");

        this.handleItemChange = this.handleItemChange.bind(this);
    }

    handleSummaryClick = (e) => {
        this.setState({openSummary: true, anchorEl: e.target});
    }

    handleSummaryClose = () => {
        this.setState({openSummary : false, anchorEl: null});
    }

    exportReport = (e) => {
        this.props.isLoading(true);
        let products = !this.state.selected_products || this.state.selected_products.id === "-1" ? null : this.state.selected_products.id;
        let warehouses = this.state.selected_warehouses === "-1" ? null : this.state.selected_warehouses;
        let txn_type = this.state.selected_txn_type === "-1" ? null : this.state.selected_txn_type;

        TransactionService.getTransactions(global.organisation, 0, this.state.totalItems, moment(this.state.startdate).format(format_without_time), moment(this.state.enddate).format(format_without_time), products, warehouses, txn_type)
        .then((data) => {
            console.log(data);
            if(data.transactions){
                let sheetname = 'Transactions';
                let exportStoreData = [];
                let title = ["Sl. No", "Transaction ID", "Transaction Date", "Type", "Warehouse", "Product Name", "Rate", "Quantity", "Unit"];
                exportStoreData = [title];
                let index = 1;
                data.transactions.forEach((txn) => {
                    txn.items.forEach((item) => {
                        let storeArray = [index++, txn.transaction_id ,moment(txn.txn_date).format(format_export), txn.txn_type, txn.warehouse, item.product_name, parseFloat(item.rate), (isInt(parseFloat(item.qty))? parseInt(item.qty) : parseFloat(item.qty)), item.product_unit];
                        exportStoreData.push(storeArray);
                    });
                });

                if(exportStoreData){
                    const wb = XLSX.utils.book_new();
                    const wsAll = XLSX.utils.aoa_to_sheet(exportStoreData);
                    XLSX.utils.book_append_sheet(wb, wsAll, sheetname );
                    XLSX.writeFile(wb, sheetname + ".xlsx");
                }
                
            }
            
            this.props.isLoading(false);
        })
        .catch(error => {
            console.log(error);
            this.props.isLoading(false);
        });
    }

    getSteps = () => {
        return ['Select Warehouse', 'Select Transaction Type', 'Fill in the Details'];
    }

    componentDidMount(){
        this.fetchMasterData();
        this.fetchData(0, this.state.numItemsPerPage);
    }

    fetchMasterData = () => {
        this.fetchTxnTypeFilter();
        this.fetchProductsFilter();
        this.fetchWarehousesFilter();
    }

    fetchData(start, numberItems){
        // this.props.isLoading(true);
        let products = !this.state.selected_products || this.state.selected_products.id === "-1" ? null : this.state.selected_products.id;
        let warehouses = this.state.selected_warehouses === "-1" ? null : this.state.selected_warehouses;
        let txn_type = this.state.selected_txn_type === "-1" ? null : this.state.selected_txn_type;

        TransactionService.getTotalCount(global.organisation, moment.utc(this.state.startdate).format(format_without_time), moment.utc(this.state.enddate).add("1", "day").format(format_without_time), products, warehouses, txn_type)
        .then((data) => {
            console.log(data);
            let summary = [];
            if(data.summary.INWARD.length > 0){
                summary.push(<Divider key={"INWARD_DIV1"}/>);
                summary.push(<Typography key={"INWARD"} className={this.props.classes.typography} variant="caption">Inward</Typography>);
                data.summary.INWARD.forEach((element, index) => {
                    summary.push(<Typography key={"INWARD" + index} className={this.props.classes.typography} variant="subtitle1">Qty: {parseFloat(element.total_qty).toFixed(2)} {element.unit}, Amount: {formatNumber(element.total_amount)}</Typography>)
                });
            } 
            if(data.summary.OUTWARD.length > 0){
                summary.push(<Divider key={"OUTWARD_DIV1"}/>);
                summary.push(<Typography key={"OUTWARD"} className={this.props.classes.typography} variant="caption">Outward</Typography>);
                data.summary.OUTWARD.forEach((element, index) => {
                    summary.push(<Typography key={"OUTWARD" + index} className={this.props.classes.typography} variant="subtitle1">Qty: {parseFloat(element.total_qty).toFixed(2)} {element.unit}, Amount: {formatNumber(element.total_amount)}</Typography>)
                });
            }
            this.setState({
                totalItems: toInt(data.count), 
                totalPages: Math.ceil(data.count / this.state.numItemsPerPage) - 1,
                current_rows: [],
                summary
            });
            if(toInt(data.count) > 0){
                this.fetchPage(start, numberItems);
            } else {
                this.props.isLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    fetchPage = (start, numberItems) => {
        this.props.setLinearLoading(true);
        let rows = [];
        let products = !this.state.selected_products || this.state.selected_products.id === "-1" ? null : this.state.selected_products.id;
        let warehouses = this.state.selected_warehouses === "-1" ? null : this.state.selected_warehouses;
        let txn_type = this.state.selected_txn_type === "-1" ? null : this.state.selected_txn_type;
        TransactionService.getTransactions(global.organisation, start, numberItems, moment.utc(this.state.startdate).format(format_without_time), moment.utc(this.state.enddate).format(format_without_time), products, warehouses, txn_type)
        .then((data) => {
            console.log(data);
            this.setState({data: data.transactions});
            if(data.transactions){
                data.transactions.forEach((txn, index) => {
                    rows.push(<TransactionDetails slno={index+1+(this.state.currentPageNumber * this.state.numItemsPerPage)} index={index+1} key={txn.transaction_id} txn={txn} {...this.props} showDetails={this.showDetails} editTxn={this.editTxn} deleteTxn={this.deleteTxn}/>);
                });
                this.setState({
                    current_rows: rows
                });
            }
            
            this.props.isLoading(false);
            this.props.setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            this.props.isLoading(false);
            this.props.setLinearLoading(false);
        });
    }

    deleteTxn = (e, txn) => {
        console.log(txn);
        if(txn.transaction_id){
            this.props.showConfirmDialog("Delete Transaction", "Confirm", () => {
                let headers = {user_id : this.props.user.id };
                headers['platform'] = 'web';
                TransactionService.deleteTransaction(global.organisation, headers, txn.transaction_id)
                .then(() => {
                    this.fetchData(this.state.currentPageNumber * this.state.numItemsPerPage, this.state.numItemsPerPage);
                    this.props.closeConfirmDialog();
                })
                .error(() => {
                    this.props.showSnackBar(`Failed to delete Transaction`, 'error');
                    this.props.closeConfirmDialog();
                })
            });
        }
    }

    handleChangeRowsPerPage = event => {
        this.setState({ numItemsPerPage : event.target.value, currentPageNumber: 0 }, () => this.fetchPage(0, this.state.numItemsPerPage, ''));
    }

    handleChangePage = (event, page) => {
        if(this.state.currentPageNumber > page){
            this.fetchRow(this.state.currentPageNumber - 1);
        } else {
            this.fetchRow(this.state.currentPageNumber + 1);
        }
    };

    fetchRow = (newCurrentPage) => {
        this.fetchPage(newCurrentPage * this.state.numItemsPerPage, this.state.numItemsPerPage);
        this.setState({ currentPageNumber: newCurrentPage });
    }

    fetchWarehousesFilter = () => {
        WarehouseService.getWarehouses(global.organisation)
        .then((data) => {
            let warehouse_name_rows = data.warehouse.map((w) => (
                <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
            ));
            warehouse_name_rows.unshift(<MenuItem key={"-1"} value={"-1"}>All Warehouses</MenuItem>);
            this.setState({master_warehouses: data.warehouse, warehouse_name_rows});
        })
        .catch((error) =>{
            console.log("Error while fetching Warehouse list");
        });
      }

    fetchProductsFilter = () => {
        ProductService.getItemList(global.organisation)
        .then((data) => {
            let product_name_rows = data.products.map((element) =>(
                
                ({id: element.id, name: element.name, value: element.name, label: element.name, unit: element.unit, decimal_precision: element.decimal_precision})
            ));   
            let selected_products = {id: "-1", name: "All Products", value: "-1", label: "All Products", unit: "-1"};
            product_name_rows.unshift(selected_products);
            this.setState({master_products: data.products, product_name_rows, selected_products});
            this.props.isLoading(false);
        })
        .catch(error => {
            console.log(error);
            this.props.isLoading(false);
        });
    }

    fetchTxnTypeFilter = () => {
        let txn_type_rows = this.state.master_txn_types.map((txn_type) => (
            <MenuItem key={txn_type.id} value={txn_type.id}>{txn_type.name}</MenuItem>
        ));   
        txn_type_rows.unshift(<MenuItem key={"-1"} value={"-1"}>All Transaction Types</MenuItem>);
        this.setState({txn_type_rows: txn_type_rows});
    }

    handleTxnTypeSelection = (e) => {
        this.setState({selected_txn_type: e.target.value}, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleWarehouseSelection = (e) => {
        this.setState({selected_warehouses: e.target.value}, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleProductSelection = (product) => {
        this.setState({selected_products: product}, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    productDropDownUI = (handleProductSelection, selectedProduct, options) => {
        // return ( <AutoCompleteSelection
        //             placeHolder="Select Product"
        //             onChange={handleProductSelection}
        //             name="product"
        //             isMulti={false}
        //             value={[selectedProduct]}
        //             options={options}/>);
        return <ItemSelection
                    required 
                    size="medium"
                    label='Select Product'
                    value={selectedProduct || null} 
                    //labelTemplate={(option) => `${option.branch} - ${option.username}`}
                    optionLabel="name"
                    //error={errorList.bm_code}
                    options={options}
                    style={{zIndex: 500, marginTop: 8, marginLeft: 0}}
                    //formClass={classes.formAutoClass}
                    selected={(value) => {
                        handleProductSelection(value)
                    }}/>
    }

    handleChangeStartDate = (date) => {
        console.log(date);
        this.setState({
            startdate: date,
        }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleChangeEndDate = (date) => {
        this.setState({
            enddate: date,
        }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    showDetails = (e, transaction) => {
        let txn_details = JSON.parse(JSON.stringify(transaction));
        txn_details.comments = txn_details.items[0].comments;
        let txn_details_rows = this.txnItemDetailsRows(txn_details);
        this.setState({txn_details, txn_details_rows});
        this.handleShowDetails();
    }

    txnItemDetailsRows = (txn_details) => {
        let items = txn_details.items;
        return items.map(item => 
            ItemDisplayDetails(item, this.props)
        );
    }

    handleShowDetails = () => {
        this.setState({ showDetails: true });
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleCloseDetails = () => {
        this.setState({ showDetails: false,
            txn_details: null,
            txn_details_rows: []
        });
    }

    handleClose = (showMessage = true) => {
        if(showMessage){
            this.props.showConfirmDialog("Are you sure you want to cancel the transaction?", undefined, () => {
                this.setState({ show: false, new_txn : {}, activeStep: 0, add_item_rows: [] });
                this.props.closeConfirmDialog();
            }, "No", "Yes");
        } else {
            this.setState({ show: false, new_txn : {}, activeStep: 0, add_item_rows: [] });
        }
        
    }

    newTransaction = () => {
        this.handleShow();
    }

    editTxn = (e, txn) => {
        let new_txn = Object.assign({}, txn);
        new_txn.txn_date = getTimeInIST(new_txn.txn_date);
        new_txn.comments = new_txn.items[0].comments;
        if(new_txn.transaction_id){
            let items = new_txn.items.map(element => ({
                    txn_item_id: element.id, 
                    id: element.product_id,
                    name: element.product_name, 
                    value: element.product_name, 
                    label: element.product_name, 
                    unit: element.product_unit, 
                    rate: element.rate,
                    qty: element.qty,
                    stock: 0,
                    decimal_precision: element.decimal_precision
            }));
            new_txn.items = items;

            if(new_txn.txn_type === "OUTWARD"){
                this.fetchProductsForWarehouse(new_txn.warehouse_id);
            } else if(new_txn.txn_type === "INWARD"){
                this.fetchProductsForInward();
            }
            
            this.setState({ new_txn, activeStep: 2, add_item_rows: this.updateItemRows(items) }, () => this.handleShow());
        }
    }

    handleStepNext = () => { 
        let {activeStep, new_txn} = this.state;
        let moveNext = false;
        switch (activeStep) {
            case 0:
                moveNext =  new_txn.warehouse_id ? true : false;
                break;
            case 1:
                moveNext =  new_txn.txn_type ? true : false;
                break;
            default:
                break;
        } 
        if(moveNext){
            this.setState((prevState) =>  ({
                activeStep : prevState.activeStep + 1
                })
            );
        }    
        
    };
    
    handleStepBack = () => {
        this.setState(prevState => ({
            activeStep : prevState.activeStep - 1
            })
        );
    };

    handleChange = (e) => {
        let {new_txn, add_item_rows} = this.state;
        if(e.target){
            new_txn[e.target.name] = e.target.value;
            if(e.target.name === 'txn_type'){
                if(e.target.value === 'OUTWARD')
                    this.fetchProductsForWarehouse(new_txn.warehouse_id);
                else 
                    this.fetchProductsForInward();

                new_txn.txn_date = moment();
            }
        } else {
            if(!new_txn.items){
                new_txn.items = [];
            }
            let item = {id: e.id, rate: e.rate, qty: e.qty, name : e.name, stock : e.stock, unit: e.unit, decimal_precision: e.decimal_precision}
            new_txn.items.push(item);
            add_item_rows = this.updateItemRows(new_txn.items);
            // add_item_rows.push(<ItemDetails key={item.id} item={item} removeItem={this.removeItem} handleItemChange={this.handleItemChange} txn_type={new_txn.txn_type} {...this.props}/>);

        }
       
        this.setState({
            new_txn, add_item_rows
        }, () => {
            if((this.state.activeStep === 0 || this.state.activeStep === 1) && e.target.value) {
                this.handleStepNext();
            }
        });
    }

    handleItemChange(e, field){
        let i = this.state.new_txn.items;
        let item = i.filter((i) => i.id === e.target.name);
        if(item.length === 1){
            item[0][field] = parseFloat(e.target.value);
            this.setState((prevState) => ({
                new_txn: {
                            ...prevState.new_txn,
                            items: i
                }
            }));
        }
    }

    removeItem = (e, item) => {
        let i = this.state.new_txn.items;
        let tItem = i.filter((i) => i.id === item.id);
        if(tItem.length > 0){
            if(!tItem[0].rate || tItem[0].rate === 0 || !tItem[0].qty || tItem[0].qty === 0){
                i = i.filter((i) => i.id !== tItem[0].id);
                this.confirmRemove(i);
            } else {
                this.props.showConfirmDialog("Are you sure you want to remove?", "", () => {
                    i = i.filter((i) => i.id !== tItem[0].id);
                    this.confirmRemove(i);
                    this.props.closeConfirmDialog();
                });
            }
        } else {
            i = i.filter((i) => i.id !== tItem[0].id);
            this.confirmRemove(i);
        }
    }

    confirmRemove = (i) => {
        this.setState(prevState => ({
            new_txn: {
                ...prevState.new_txn,
                items: i
            },
            add_item_rows: this.updateItemRows(i)
        }))
    }

    updateItemRows = (items) => {
        return items.map((item, index) => (
            <ItemDetails index={index + 1} key={item.id} item={item} removeItem={this.removeItem} handleItemChange={this.handleItemChange} txn_type={this.state.new_txn.txn_type} {...this.props}/>
        ));
    }

    handleChangeDate = (date) => {
        this.setState(prevState => ({
            new_txn: {
                ...prevState.new_txn,
                txn_date: date
            }
        }));
    }

    fetchProductsForWarehouse = (warehouse_id) => {
        WarehouseService.getStockForWarehouse(global.organisation, warehouse_id, 0, 999)
        .then((data) => {
            console.log(data)
            let product_stock_rows = data.stock.map((element) =>( 
                ({id: element.product_id, txn_item_id: undefined, name: element.name, value: element.name, label: element.name, unit: element.product_unit, stock: parseFloat(element.stock), decimal_precision: element.decimal_precision})
            ));
            this.setState({product_stock_rows});
        })
        .catch(error => {
            console.log(error);
        });
    }

    fetchProductsForInward = () => {
        ProductService.getItemList(global.organisation)
        .then((data) => {
            console.log(data)
            let product_stock_rows = data.products.map((element) =>( 
                ({id: element.id, txn_item_id: undefined, name: element.name, value: element.name, label: element.name, unit: element.unit, stock: 0, decimal_precision: element.decimal_precision})
            ));
            this.setState({product_stock_rows});
        })
        .catch(error => {
            console.log(error);
        });
    }

    confirmSave = () => {
        let new_txn = Object.assign({}, this.state.new_txn);
        new_txn.transactions = new_txn.items.map(i => ({
            id: i.txn_item_id ? i.txn_item_id : null,
            txn_type: new_txn.txn_type,
            warehouse_id : new_txn.warehouse_id,
            product_id: i.id,
            txn_date: moment(new_txn.txn_date).format(format),
            comments: new_txn.comments ? new_txn.comments : '',
            qty: i.qty,
            rate: i.rate,
        }));
        new_txn.txn_date = moment.utc(new_txn.txn_date).format(format);
        delete new_txn.items;
        console.log(new_txn);
        this.props.isLoading(true);
        let headers = {user_id : this.props.user.id };
        headers['platform'] = 'web';
        TransactionService.createTransaction(global.organisation, headers, new_txn)
        .then(() => {
            this.props.closeConfirmDialog();
            this.handleClose(false);
            this.fetchData(this.state.currentPageNumber * this.state.numItemsPerPage, this.state.numItemsPerPage);
            this.props.showSnackBar(`Transaction created.` , 'success');
        })
        .catch(error => {
            this.props.showSnackBar(`Something went wrong. Please try again later.` , 'error');
            this.props.closeConfirmDialog();
            this.props.isLoading(false);
        });
    }


    createModifyTransaction = (e) => {
        e.preventDefault();
        let new_txn = this.state.new_txn;
        if(new_txn.warehouse_id && new_txn.txn_type && new_txn.txn_date && new_txn.items.length > 0 && new_txn.items.filter(i => ( ((i.qty || 0) > 0) && ((i.rate || 0) >= 0))).length === new_txn.items.length){
            // && moment(new_shipment.shipment_date) >= moment().startOf('day')
            this.props.showConfirmDialog("Create Transaction?", "Confirm", this.confirmSave);
            return;
        }

        if(new_txn.items.length === 0 || new_txn.items.filter(i => ( ((i.qty || 0) > 0) && ((i.rate || 0) >= 0))).length !== new_txn.items.length){
            this.props.showSnackBar('Please add items, their quantities and proceed', 'error');
        }
    }

    render() {

        const {classes} = this.props;
        const {message, activeStep, steps, new_txn} = this.state;
        return (
            <div className="Home">
                <FormControl variant="outlined" className={classes.formControlLeft} style={{ float: "left"}}>
                    <InputLabel
                        ref={ref => {
                        this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-warehouse-simple"
                    >
                        Warehouses
                    </InputLabel>
                    <Select
                        value={this.state.selected_warehouses}
                        onChange={this.handleWarehouseSelection}
                        input={
                        <OutlinedInput
                            labelWidth={50}
                            name="Warehouse"
                            id="outlined-warehouse-simple"
                        />
                        }
                    >
                        {this.state.warehouse_name_rows}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ float: "left" }}>
                    <InputLabel
                        ref={ref => {
                        this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-txn_type-simple"
                    >
                        Txn Type
                    </InputLabel>
                    <Select
                        value={this.state.selected_txn_type}
                        onChange={this.handleTxnTypeSelection}
                        input={
                        <OutlinedInput
                            labelWidth={50}
                            name="Project"
                            id="outlined-txn_type-simple"
                        />
                        }
                    >
                        {this.state.txn_type_rows}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControlAutoComplete} style={{ float: "left"}}>
                    {this.productDropDownUI(this.handleProductSelection, this.state.selected_products, this.state.product_name_rows)}
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        margin="normal"
                        label="From"
                        value={this.state.startdate}
                        onChange={this.handleChangeStartDate}
                        format='DD MMM YYYY'
                        maxDate={this.state.enddate}
                        className={classes.formControl}
                        style={{float: "left", height: "100%", marginTop: "24px"}}
                    />
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        margin="normal"
                        label="To"
                        value={this.state.enddate}
                        onChange={this.handleChangeEndDate}
                        maxDate={moment()}
                        format='DD MMM YYYY'
                        className={classes.formControl}
                        style={{float: "left", height: "100%", marginTop: "24px"}}
                    />
                </MuiPickersUtilsProvider>
                { this.state.current_rows.length > 0 &&
                    <Tooltip title="Summary">
                        <IconButton aria-label="View Summary" color="primary" className={classes.txn_details} onClick={this.handleSummaryClick}>
                            <Icon>library_books</Icon>
                        </IconButton>
                    </Tooltip>
                }
                <Popover
                    id="simple-popover"
                    open={this.state.openSummary}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleSummaryClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >   
                <section className={classes.summary}>
                    <Typography className={classes.typography} variant="h6">Summary</Typography>
                    {this.state.summary}
                </section>
                </Popover>
                <div style={{float: 'right'}}>
                </div>
                
                <div style={{marginBottom: 80}}>
                    <p style={{color:"red", paddingLeft:"20px"}}>{message}</p>
                    { !message &&
                        <Paper className={classes.root} style={{width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
                            <EnhancedTableToolbar title={"Transactions"} exportReport={this.exportReport}/>
                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} stickyHeader size="small">
                                    <EnhancedTableHead
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={() => {}}
                                        rows ={this.state.columns} />
                                        { !this.props.loading && 
                                            <TableBody>
                                                {this.state.current_rows}
                                            </TableBody>
                                        }
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={RowCountOptions}
                                component="div"
                                count={this.state.totalItems}
                                rowsPerPage={this.state.numItemsPerPage}
                                page={this.state.currentPageNumber}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                        </Paper>
                    }
                </div>
                <DialogMUI
                    fullScreen
                    fullWidth
                    maxWidth={"lg"}
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.show}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {new_txn.transaction_id ? "Update" : "Create"} Transaction
                            </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" component={'span'}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                <Grid container spacing={0}>
                                {
                                    activeStep === 0 && 
                                        <Grid item xs={6}>
                                            <SelectDropdown required disabled={this.state.master_warehouses.length === 0} label="Select Warehouse" hint="Select Warehouse" name="warehouse_id" options={this.state.master_warehouses} value={new_txn.warehouse_id ? new_txn.warehouse_id : "-1"} onChange={this.handleChange} />
                                        </Grid>
                                }
                                {   
                                    activeStep > 0 && 
                                    <Grid item xs={3}>
                                        <Typography className={classes.instructions} variant="subtitle1">
                                            Warehouse
                                        </Typography>
                                    </Grid>
                                }
                                {   
                                    activeStep > 0 && 
                                    <Grid item xs={3}>
                                        <Typography className={classes.instructions} variant="body2">
                                            {this.state.master_warehouses.filter(w => w.id === new_txn.warehouse_id).length === 1 && this.state.master_warehouses.filter(w => w.id === new_txn.warehouse_id)[0].name}
                                        </Typography>
                                    </Grid>
                                }
                                {   
                                    activeStep === 1 && 
                                    <Grid item xs={6}>
                                        <SelectDropdown required label="Select Type" hint="Select Type" name="txn_type" options={this.state.master_txn_types} value={new_txn.txn_type ? new_txn.txn_type : "-1"} onChange={this.handleChange} />
                                    </Grid>
                                }
                                {   
                                    activeStep > 1 && 
                                    <Grid item xs={3}>
                                        <Typography className={classes.instructions} variant="subtitle1">
                                            Transaction Type
                                        </Typography>
                                    </Grid>
                                }
                                {   
                                    activeStep > 1 && 
                                    <Grid item xs={3}>
                                        <Typography className={classes.instructions} variant="body2">
                                            {new_txn.txn_type}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    activeStep === 2 &&
                                    <Grid item xs={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DateTimePicker
                                                margin="normal"
                                                label="Transaction Date"
                                                value={new_txn.txn_date}
                                                onChange={this.handleChangeDate}
                                                format='DD MMM YYYY HH:mm'
                                                maxDate={moment()}
                                                inputVariant="outlined"
                                                variant="inline"
                                                disableToolbar
                                                className={classes.formControl}
                                                style={{float: "left", height: "100%", marginTop: "24px", marginLeft: "10px"}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                }
                                {
                                    activeStep === 2 &&
                                    <Grid item xs={6}>
                                        <Grid container >
                                            <Grid item xs={6} lg={8}>
                                                <FormControl variant="outlined" className={classes.formControlAutoComplete}>
                                                    {this.productDropDownUI(this.handleChange, null, this.state.product_stock_rows.filter(p => (!new_txn.items || (new_txn.items.filter(pp =>  p.id === pp.id).length === 0))))}
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                                <Button variant="outlined" color="primary" size="large" style={{marginTop: 24}}>Add Item</Button>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    activeStep === 2 &&
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                }
                                {
                                    activeStep === 2 &&
                                    <Grid item xs={12}>
                                        <List className={classes.list}>                               
                                            {this.state.add_item_rows}
                                        </List>
                                    </Grid>
                                }
                                </Grid>
                                <TextField
                                    id="comment"
                                    label="Comments"
                                    placeholder="Comments"
                                    name="comments"
                                    fullWidth
                                    defaultValue={new_txn.comments}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    multiline
                                    rows="2"
                                    type="text"
                                    />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary" className={classes.button}>
                                Cancel
                            </Button>
                            {   activeStep < steps.length - 1 &&
                                <Button disabled={activeStep === 0} onClick={this.handleStepBack} className={classes.button}>
                                    Back
                                </Button>
                            }
                            { activeStep !== steps.length - 1 ? 
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleStepNext}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button> 
                            :
                                <Button onClick={this.createModifyTransaction} color="primary" autoFocus className={classes.button}>
                                    Save
                                </Button>
                            }
                        </DialogActions>
                </DialogMUI>
                { this.state.master_warehouses.length > 0 && 
                    <Fab onClick={this.newTransaction} color="primary" aria-label="Add" className={this.props.classes.fab} style={{position: 'fixed', top: '85%', left: '90%'}}>
                        <AddIcon />
                    </Fab>
                }
                {this.state.txn_details &&
                    <DialogMUI
                        fullWidth
                        maxWidth={'md'}
                        TransitionComponent={Transition}
                        open={this.state.showDetails}
                        onClose={this.handleCloseDetails}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        scroll='paper'>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                <IconButton color="inherit" onClick={this.handleCloseDetails} aria-label="Close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                    Transaction Details
                                </Typography>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" component={'span'}>
                                    <Grid container spacing={8} style={{flexGrow: 1, marginLeft: 10, marginBottom: 15}} justify="flex-start" alignItems="center">
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Transaction Date</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {getTimeInIST(this.state.txn_details.txn_date).format(format_display_date)}
                                        </Grid>
                                        <Grid item xs={3}/>
                                        <Grid item xs={3} >
                                            {this.state.txn_details.txn_type === 'INWARD' ? <Avatar className={classes.blueAvatar}>IN</Avatar> : <Avatar className={classes.redAvatar}>OUT</Avatar> }
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Warehouse</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {this.state.txn_details.warehouse}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Total Amount</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {formatNumber(this.state.txn_details.total_amount)}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Comments</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {this.state.txn_details.comments}
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Table className={classes.table}>
                                        <TableHead>
                                        <TableRow>
                                            <CustomTableCell>Item</CustomTableCell>
                                            <CustomTableCell padding='none'>Qty</CustomTableCell>
                                            <CustomTableCell align="right" padding='none'>Unit</CustomTableCell>
                                            <CustomTableCell align="right">Rate</CustomTableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.txn_details_rows}
                                        </TableBody>
                                    </Table>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDetails} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                    </DialogMUI>
                }
            </div>
        );
    }

}

class TransactionDetails extends Component {

    render(){
      const {txn, slno, showDetails, deleteTxn, editTxn} = this.props;
      
       let qty = Object.keys(txn.qty_per_unit).map((q, index) =>  {
           let qtyValue = parseFloat(txn.qty_per_unit[q]);
            if(isInt(qtyValue)){
                return qtyValue+ " " + q + (index === Object.keys(txn.qty_per_unit).length -1 ? "" : ",  ");
            } else {
                return qtyValue.toFixed(3)+ " " + q + (index === Object.keys(txn.qty_per_unit).length -1 ? "" : ",  ");
            }
        });

        return (
            <TableRow key={txn.id}>
                {/* <TableCell component="th" scope="row" align="center" padding='none'>
                    {txn.txn_type === 'INWARD' ? <Avatar className={classes.blueAvatar}>IN</Avatar> : <Avatar className={classes.redAvatar}>OUT</Avatar> }
                </TableCell> */}
                <TableCell component="th" scope="row" align="center" padding='none'>
                    <Typography variant="subtitle2">{slno}</Typography>
                </TableCell>
                <TableCell align="center"  padding='none'>{getTimeInIST(txn.txn_date).format(format_display_date)}</TableCell>
                <TableCell align="center"  padding='none'><Typography variant="subtitle2"> {txn.txn_type === 'INWARD' ? <CallReceivedIcon color="primary" style={{verticalAlign: "bottom"}}/>  : <CallMadeIcon color="secondary" style={{verticalAlign: "bottom"}}/> }{txn.txn_type}</Typography></TableCell>
                <TableCell align="center"  padding='none'><Typography variant="subtitle2">{txn.warehouse}</Typography></TableCell>
                <TableCell align="center"  padding='none'><Typography variant="subtitle2">{txn.item_count}</Typography></TableCell>
                <TableCell align="center"  padding='none'><Typography variant="subtitle2">{qty}</Typography></TableCell>
                <TableCell align="center"  padding='none'>
                    <Button variant="outlined" color="primary" onClick={(e) => showDetails(e, txn)}>View Details</Button>
                    <Tooltip title="Edit Transaction">
                        <IconButton aria-label="edit" color="primary" onClick={(e) => editTxn(e, txn)}>
                            <Icon>edit_icon</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" color="secondary" onClick={e => deleteTxn(e, txn)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
}

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function ItemDisplayDetails (item, props) {

    const classes = props.classes;

    return(
        <TableRow key={item.id} className={classes.row}>
            <CustomTableCell  style={{paddingRight: 5}}>
                <ListItemText 
                    primary={item.product_name} 
                    secondary={item.warehouse}
                    style={{paddingRight: 5}}/>
            </CustomTableCell>
            <CustomTableCell padding='none'>{item.qty}</CustomTableCell>
            <CustomTableCell align="right" padding='none'>{item.product_unit}</CustomTableCell>
            <CustomTableCell align="right">{formatNumber(item.rate)}</CustomTableCell>
        </TableRow>
    );
}

class ItemDetails extends Component {
    constructor(props){
        super(props);

        let {item} = this.props;

        this.state = {
            error : {
                qty:  item.qty || parseFloat(item.qty) > 0 ? false : true,
                rate: item.rate || parseFloat(item.rate) < 0 ? false : true
            }
        };
    }
    
    handleChange(e, field){
        let {error} = this.state;
        let {txn_type, item, handleItemChange} = this.props;
        switch (field) {
            case "qty":
                error.qty = ((parseFloat(e.target.value) || 0) <= 0) || (txn_type === 'OUTWARD' && parseFloat(e.target.value) > item.stock);
                if(!error.qty){
                    handleItemChange(e, field);
                }
                break;
            case "rate":
                error.rate = (parseFloat(e.target.value) || 0) < 0;
                if(!error.rate){
                    handleItemChange(e, field);
                }
                break;
            default:
                break;
        }
        this.setState({error});
    }
    render() {
        const {classes, removeItem, item, txn_type, index} = this.props;
        const {error} = this.state;
        return(
            <ListItem key={item.id} dense button>
                <ListItemText primary={<Typography type="subtitle1">{index}{". "}{item.name}</Typography>} />
                {   txn_type === 'OUTWARD' &&
                    <ListItemText primary={<Typography type="subtitle1"><b>Current Stock:</b> {item.stock}</Typography>}/>
                }
                <TextField
                    id="standard-number"
                    label="Qty"
                    defaultValue={item.qty}
                    onChange={(e) => this.handleChange(e, 'qty')}
                    type="number"
                    name={item.id}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={error.qty}
                    className={classes.qtyField}
                    margin="normal"
                    variant="outlined"
                    required
                    helperText={`Enter Qty ${item.unit ? `in ${item.unit}` : ""}`}
                    style={{width: 200}}
                />
                <TextField
                    id="standard-number"
                    label="rate"
                    defaultValue={item.rate}
                    onChange={(e) => this.handleChange(e, 'rate')}
                    type="number"
                    name={item.id}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={error.rate}
                    className={classes.qtyField}
                    margin="normal"
                    variant="outlined"
                    required
                    helperText="Enter Rate"
                    style={{width: 200}}
                />
                <ListItemSecondaryAction>
                    <Tooltip title="Remove">
                        <IconButton aria-label="Delete" onClick={(e) => removeItem(e, item)} className={classes.icon}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip> 
                </ListItemSecondaryAction>
            </ListItem>
        );
    }
}

export default withStyles(styles)(Transactions);