import React, { Component } from "react";
import {Form} from 'react-bootstrap';
import { WarehouseService } from "../Services/WarehouseService";
import AddIcon from '@material-ui/icons/Add';
import {TableRow, Tooltip, Icon, IconButton} from '@material-ui/core';
import {
    Grid,
    Paper,
    Typography,
    withStyles,
    Button,
    TextField,
    Modal,
    Divider,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
} from "@material-ui/core";
import { ImportService } from "../Services/ImportService";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";

function getModalStyle() {
    const top = 50 ;
    const left = 50 ;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflow:'scroll',
        height:'80%',
        width: '50%'
    };
}

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    upload: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30%',
      },
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    table: {
        // overflowX: 'auto',
        // display: 'block',
        minWidth: 500,
    },
  });

class WareHouse extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        show: false,
        new_warehouse: { name: "", address: "", city: "", state: "", zip: ""}
      }
      props.setTitle("Warehouses");

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.edit = this.edit.bind(this);
      this.updateRows = this.updateRows.bind(this);

      this.fileInput = React.createRef();
    }

    confirmSave = () => {
        this.handleClose();
        this.props.closeConfirmDialog();
        this.createWarehouseAPI(this.state.new_warehouse);
    }

    createModifyWarehouse = (e) => {
        e.preventDefault();
        if(this.state.new_warehouse.name && this.state.new_warehouse.city && this.state.new_warehouse.state && this.state.new_warehouse.zip){
            this.props.showConfirmDialog("Confirm?", undefined, this.confirmSave);
        } else {
            this.props.showSnackBar('Please fill all the details and continue', 'error');
        }
    }

    importFile = () => {
        let files = this.fileInput.current.files;
        if(files.length === 1){
          let reader = new FileReader();
          reader.readAsDataURL(files[0]);
          let name = `${global.organisation}.jpg`;
          reader.onload = (e) => {
            this.props.isLoading(true);
            ImportService.uploadMap(global.organisation, {name: name, file: e.target.result})
            .then((data) => {
                this.props.isLoading(false);
                if(data.success){
                    this.props.showSnackBar("File Uploaded successfully", 'success');
                }
            })
            .catch((error) => {
                this.props.isLoading(false);
                this.props.showSnackBar("File upload failed. Please try again. Check Extension and file name.", 'error');
                console.log(error);
            });
          }
        }
    }

    createWarehouseAPI(new_warehouse){
        let body = new_warehouse;
        this.props.isLoading(true);
        let headers = {user_id : this.props.user.id };
        WarehouseService.create(global.organisation, headers, body)
        .then((response) => {
            if(response.status === 409){
                this.props.showSnackBar(`${new_warehouse.name} already exists.`, 'error');
            } else {
                this.setState({
                    new_warehouse: { name: "", address: "", city: "", state: "", zip: ""}
                });
                this.fetchData();
                if(this.state.show) this.handleClose();
                this.props.showSnackBar(`${new_warehouse.id ? 'Saved' : 'Created'} Warehouse: ${new_warehouse.name}`, 'success');
            }
            this.props.isLoading(false);
        })
        .catch(() => {
            this.props.isLoading(false);
            this.props.showConfirmDialog(`Failed to create warehouse : ${new_warehouse.name}. Please try again.`, undefined, undefined);
        });
        
    }

    edit(e, warehouse){
        e.preventDefault();
        if(warehouse){
            this.setState({new_warehouse: {id: warehouse.id, name: warehouse.name, address: warehouse.address, city: warehouse.city, state: warehouse.state , zip: warehouse.zip}}, function () {
                this.handleShow();
            });
        } else {
            this.setState({show: true});
        }
    }
 
    componentDidMount(){
        this.fetchData();
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_warehouse: { name: "", address: "", city: "", state: "", zip: ""}
        });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleChange = (e) => {
        let change = this.state.new_warehouse;
        change[e.target.name] = e.target.value;
        e.preventDefault();
        this.setState(change);        
    }


    fetchData(){
        this.props.isLoading(true);
        WarehouseService.getWarehouses(global.organisation)
        .then((data) => {
            console.log(data);
            this.setState({data: data.warehouse,  originalData: data.warehouse});
            this.filterCriteria();
            this.props.isLoading(false);
        })
        .catch(error => {
            console.error(error);
            this.props.isLoading(false);
        });
    }

    updateRows(data){
        let rows = [];
        data.forEach((warehouse) => {
            rows.push(<WarehouseDetails key={warehouse.id} warehouse={warehouse} remove={this.remove} edit={this.edit}/>);
          });
        this.setState({rows: rows});
    }

    filterCriteria = (e) => {
        this.props.isLoading(true);
        let searchText = "";
        if(e){
            e.preventDefault();
            searchText = e.target.value;
        }
        let filteredList = this.state.originalData.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()));
        if(searchText.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Warehouse'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    remove = (e, id) => {
        e.preventDefault();
        if(id) {
            this.props.showConfirmDialog("This will remove all the warehouse transactions and clear up the stock in that particular warehouse.", "Confirm removal of Warehouse?", () => {
                let headers = {user_id : this.props.user.id };
                WarehouseService.remove(global.organisation, headers, id)
                .then(() =>{
                    this.fetchData();
                    this.props.closeConfirmDialog();
                    this.props.showSnackBar(`Removed Warehouse`, 'success');
                })
                .catch((error) => {
                    this.props.showSnackBar(`Failed to remove Warehouse. Please try again.`, 'error');
                });
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { data, message } = this.state;
        return (
            <div className="Home">
                {/* <h3 style={{marginBottom: '5px'}}>Warehouses <Badge>{this.state.data.length}</Badge></h3> */}
                <div>
                    <Paper className={classes.upload}>
                        <Grid container spacing={0} className={classes.grid}>
                            <Grid item lg={12} xs={12}>
                                <Typography variant="h5" component="h2" align="justify">
                                    Warehouse Storage Map
                                </Typography>
                            </Grid>
                            <Grid item lg={12} xs={12}  style={{marginTop: 15}}>
                                <input type="file" name="label_file" accept=".jpg" multiple={false} ref={this.fileInput}/>
                            </Grid>
                            <Grid item lg={12} xs={12}  style={{marginTop: 15}}>
                                <Button variant="contained" color="primary" onClick={this.importFile} fullWidth>
                                    Upload file
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.root} style={{width: "100%", paddingTop : '15px', paddingLeft: "5px", paddingRight: "5px", marginTop: '10px'}}>
                        {/* <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form> */}
                        <EnhancedTableToolbar title={"Warehouses:"} subtitle={data.length} onSearchTextChange={this.filterCriteria}/>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Address</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">City</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">State</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Zip</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Total Stock</Typography></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                        {
                            message && <p style={{color:"red", paddingTop: 16, fontSize: "14pt", paddingLeft:"20px", width: "100%", textAlign: "center"}}>{message}</p>
                        }
                    </Paper>
                </div>
                <Modal 
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.show} 
                    onClose={this.handleClose}>
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="h6" id="modal-title">
                            { this.state.new_warehouse.id ? 'Modify Warehouse' : 'Create Warehouse' }
                        </Typography>
                        <Divider />
                        {this.warehouseForm(this.createModifyWarehouse, "Save")}
                    </div>
                </Modal>
                <Tooltip title="Add Warehouse">
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        );
    }

    warehouseForm = (submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="name"
                label="Warehouse Name"
                placeholder="Warehouse Name"
                name="name"
                defaultValue={this.state.new_warehouse.name}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <TextField
                id="address"
                label="Address"
                defaultValue={this.state.new_warehouse.address}
                placeholder="Address"
                name="address"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                multiline
                rows="2"
                rowsMax="2"
                />
            <TextField
                id="city"
                label="City"
                defaultValue={this.state.new_warehouse.city}
                placeholder="City"
                name="city"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                multiline
                rows="2"
                rowsMax="2"
                required
                />
            <TextField
                id="state"
                label="State"
                defaultValue={this.state.new_warehouse.state}
                placeholder="State"
                name="state"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                required
                />
            <TextField
                id="zip"
                label="Zip"
                defaultValue={this.state.new_warehouse.zip}
                placeholder="Zip"
                name="zip"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                required
                pattern="[0-9]*"
                type="number"
                InputProps={{
                    inputProps: { min: 0 }
                }}
                />
            <br/>
            <div style={{marginTop: "10px", marginLeft: "8px"}}>
                <Button variant="outlined" size="medium" color="primary" type="submit" onClick={submitFunction} style={{marginRight: "10px"}}>{buttonText}</Button>
                {this.state.show ? (<Button variant="outlined" size="medium" color="primary" onClick={this.handleClose}>Close</Button>): ""}
            </div>
        </Form>
    )

}


class WarehouseDetails extends Component {

    render(){
      const warehouse = this.props.warehouse;
      return (
        <TableRow key={warehouse.id}>
            <TableCell component="th" scope="row">
                {warehouse.name}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.address ? warehouse.address : "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.city ? warehouse.city : "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.state ? warehouse.state : "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.zip ? warehouse.zip : "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.total_stock ? warehouse.total_stock : 0}</TableCell>
            <TableCell align="right">
                <Tooltip title="Edit Warehouse">
                    <IconButton aria-label="edit" color="primary" onClick={(e) => this.props.edit(e, warehouse)}>
                        <Icon>edit_icon</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Remove Warehouse">
                    <IconButton aria-label="remove" color="primary" onClick={(e) => this.props.remove(e, warehouse.id)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
      );
    }
}

export default withStyles(styles)(WareHouse);