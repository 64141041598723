import Config from '../Config';
import { RestService } from './RestService';

export const OrganisationService = {
    getOrganisations: async function(organisation){
        return RestService.get(`${Config.get_organisations_url}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    getOrganisationStats: async function(organisation){
        return RestService.get(`${Config.get_organisation_stats_url}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    updateOrganisation: async function(organisation, headers, body){
        return RestService.post(`${Config.update_organisation}organisation=${organisation}&`, headers, body);
        // .then(response => response.json());
    },
};