import React from "react";
import { Tooltip, Popover } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import { Chip, TableCell, withStyles } from '@material-ui/core';

export function randomHex(){
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++ ) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function tooltip(text) {
  return (
    <Tooltip id="tooltip">
      <strong>{text}</strong>
    </Tooltip>
  );
}

export function popover(text) {
  return (
    <Popover id="popover-top">
      <strong>{text}</strong>
    </Popover>
  );
}

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm a";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";


export function getTimeInIST(dateString) {
  const timeInUTC = moment.utc(dateString, format);
  return timeInUTC.clone().tz("Asia/Kolkata");
}

export function currentTimeInIST() {
  return moment
    .utc()
    .clone()
    .tz("Asia/Kolkata");
}

export function toInt(n) {
  return parseInt(n, 10);
}

export function convertProjectStatusToString(status) {
  switch (status) {
    case "0":
      return "OnHold";
    case "1":
      return "In-Progress";
    case "2":
      return "Completed";
    default:
      return "None";
  }
}

export function getProjectStatusBadge(status) {
    let color = "#FFFFFF";
    switch (status) {
        case "0":
            color = "default";
            break;
        case "1":
            color = "primary";
            break;
        case "2":
            color = "secondary";
        break;
            default:
            color = "default";
        break;
    }

  return <Chip label={convertProjectStatusToString(status)} color={color} />;
}

export function formatNumber(number){
  return parseFloat(number).toLocaleString('en-IN', {style: 'currency', minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'INR'});
}

export function isInt(n) {
  return n % 1 === 0;
}

export const StyledTableCell = withStyles((theme) => ({
  root: {
      paddingLeft: 16, 
      paddingRight: 16,
      borderBottom: '1px solid rgba(238, 238, 238, 1)',
      fontSize: 14,
      height: 35,
  }
}))(TableCell);