import Config from '../Config';
import { RestService } from './RestService';
import {format_without_time} from "../Utils";
import moment from 'moment';

export const WarehouseService = {
    getWarehouses: async function(organisation){
        return RestService.get(`${Config.get_warehouses_url}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    create: async function(organisation, headers, body){
        return RestService.post(`${Config.create_wh}organisation=${organisation}&`, headers, body);
        // .then(response => response.json());
    },
    remove: async function(organisation, headers, id){
        return RestService.post(`${Config.remove_wh}organisation=${organisation}&id=${id}`, headers, {});
        // .then(response => response.json());
    },
    getSalesReport: async function(organisation, startdate, enddate, product_id){
        return RestService.get(`${Config.get_sales_report}organisation=${organisation}&startdate=${moment(startdate).format(format_without_time)}&enddate=${moment(enddate).format(format_without_time)}${ product_id ?  `&product_id=${product_id}` : ''}`, {})
        .then(response => response.json());
    },
    getPurchaseReport: async function(organisation, startdate, enddate, product_id){
        return RestService.get(`${Config.get_purchase_report}organisation=${organisation}&startdate=${moment(startdate).format(format_without_time)}&enddate=${moment(enddate).format(format_without_time)}${ product_id ?  `&product_id=${product_id}` : ''}`, {})
        .then(response => response.json());
    },
    getRateReport: async function(organisation, startdate, enddate, txn_type, product_id){
        return RestService.get(`${Config.get_rate_report}organisation=${organisation}&startdate=${moment(startdate).format(format_without_time)}&enddate=${moment(enddate).format(format_without_time)}&txn_type=${txn_type}${ product_id ?  `&product_id=${product_id}` : ''}`, {})
        .then(response => response.json());
    },
    getTotalAmount: async function(organisation, startdate, enddate, txn_type, product_id){
        return RestService.get(`${Config.get_amount_report}organisation=${organisation}&startdate=${moment(startdate).format(format_without_time)}&enddate=${moment(enddate).format(format_without_time)}${ txn_type ?  `&txn_type=${txn_type}` : ''}${ product_id ?  `&product_id=${product_id}` : ''}`, {})
        .then(response => response.json());
    },
    getStockForWarehouse: async function(organisation, warehouse_id, start, numberItems){
        return RestService.get(`${Config.get_stock}organisation=${organisation}&from=${start}&offset=${numberItems}${ warehouse_id ? `&warehouses=${warehouse_id}` : ''}`, {})
        .then(response => response.json());
    }
};