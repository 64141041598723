import { Avatar, Card, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useState } from 'react';
import MyDialog from '../components/MyDialog';
import { TemplateService } from '../Services/TemplateService';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ItemSelection from '../components/ItemSelection';
import { ProductService } from '../Services/ProductService';
import { blue, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		marginTop: theme.spacing(3),
		overflowX: "auto",
		paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        backgroundColor: '#f5f5f5',
        minHeight: '80vh'
	},
    block: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1, 1, 0, 1),
    },
    switch: {
        marginTop: theme.spacing(1)
    },
    content: {
        padding: '0px !important',
    },
    title: {
        margin: theme.spacing(1, 0, 0),
    },
    formAutoClass: {
        marginLeft: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
    },
    smallRed: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
}));

const config = {
    name: 100
}

function AddTemplate({show, id, close, ...props}) {
    const classes = useStyles();
	const { showConfirmDialog, closeConfirmDialog, isLoading, showSnackBar } = props;
    const [template, setTemplate] = useState({ratio_enabled: 0})
    const [open, setOpen] = useState(false)
    const [inward, setInward] = useState([]);
	const [outward, setOutward] = useState([]);
	const [products, setProducts] = useState([]);

    useEffect(() => {
        if(show){
            ProductService.getItemList(global.organisation)
            .then((data) => {
                let products = data.products.map((element) =>(
                    
                    ({id: element.id, name: element.name, value: element.name, label: element.name, unit: element.unit, decimal_precision: element.decimal_precision})
                ));   
                setProducts(products);
            })
            .catch(error => {
                console.log(error);
            });
            setOpen(true)
        }
	}, [show]);

    useEffect(() => {
        if(id){
            fetchData(id);
        }
	}, [id]);

    const fetchData = (id) => {
        TemplateService.getTemplateDetail(id, global.organisation)
        .then(data => {
            console.log(data)
            if(data && data.id){
                setTemplate(data)
                setInward(data.items.filter(i => i.txn_type === 'INWARD'))
                setOutward(data.items.filter(i => i.txn_type === 'OUTWARD'))
            }
        })
    }

    const handleChange = (event) => {
        event.persist()
        if(event.target.name === 'ratio_enabled'){
            setTemplate(old => ({...old, [event.target.name]: event.target.checked ? 1 : 0}))
        } else {
            setTemplate(old => ({...old, [event.target.name]: event.target.value}))
        }
    }
   
    const handleChangeItem = (type, product_id) => (event) => {
        if(type === 'inward'){
            let inList = [...inward];
            let i = inList.find(d => d.product_id === product_id)
            i.ratio = event.target.value;
            setInward(inList);
        } else {
            let outList = [...outward];
            let i = outList.find(d => d.product_id === product_id)
            i.ratio = event.target.value;
            setOutward(outList);
        }
    }

    const remove = (type, product_id) => (event) => {
        if(type === 'inward'){
            let inList = [...inward];
            setInward(inList.filter(d => d.product_id !== product_id));
        } else {
            let outList = [...outward];
            setOutward(outList.filter(d => d.product_id !== product_id));
        }
    }

    const handleProductSelection = (type, value) => {
        console.log(type, value)
        if(type === 'inward') {
            setInward([...inward, {
                product_id: value.id,
                txn_type: "INWARD",
                ratio: 1,
                "product": value
            }])
        } else {
            setOutward([...outward, {
                product_id: value.id,
                txn_type: "OUTWARD",
                ratio: 1,
                "product": value
            }])
        }
    }

    const onClose = () => {
        setOpen(false);
        setTemplate({ratio_enabled: 0});
        setInward([])
        setOutward([])
        close(false)
    }

    const onSave = () => {
        if(template.name && (inward.length > 0 || outward.length > 0)){
            isLoading(true)
            let body = {
                id: template.id || null,
                name : template.name,
                ratio_enabled: template.ratio_enabled,
                items: [
                    ...inward.map(i => ({
                        id: i.id || null,
                        product_id: i.product_id,
                        ratio: parseInt(i.ratio),
                        txn_type: i.txn_type,
                    })),
                    ...outward.map(i => ({
                        id: i.id || null,
                        product_id: i.product_id,
                        ratio: parseInt(i.ratio),
                        txn_type: i.txn_type,
                    }))
                ]
            }
            TemplateService.create(body, global.organisation, {})
            .then(data => {
                if(data.success) {
                    setOpen(false);
                    setTemplate({ratio_enabled: 0});
                    showSnackBar(data.message, 'success');
                    close(true)
                } else {
                    showConfirmDialog(data.message, undefined, () => {
                        closeConfirmDialog()
                    })
                }
            })
            .catch(error => {
                console.error(error)
                showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
                    closeConfirmDialog()
                })
            })
            .finally(() => isLoading(false))
        } else {
            showConfirmDialog('Please Provide Template name and Select Atleast one item in inward or outward list', undefined, () => {
                closeConfirmDialog()
            })
        }
    }

    return (
        <div>
            <MyDialog
				maxWidth={"md"}
                disableBackdropClick={true}
                contentClass={classes.content}
				content={
                    <div className={classes.container}>
                        <Grid container spacing={2} justify="flex-start" alignContent="flex-start">
                            <Grid item xs={12} md={7}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Template name"
                                    value={template.name || ''}
                                    variant="outlined"
                                    fullWidth
                                    name="name"
                                    onChange={handleChange}
                                    helperText={`${(template && template.name ? template.name.length : 0)} / ${config.name}`}
                                    error={template && template.name && template.name.length > config.name}
                                    InputProps={{
                                        inputProps: {
                                            minLength: 0,
                                            maxLength: config.name
                                        }
                                    }}
                                    />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    className={classes.switch}
                                    control={
                                    <Switch
                                        checked={template.ratio_enabled === 1}
                                        onChange={handleChange}
                                        name="ratio_enabled"
                                        color="primary"
                                    />
                                    }
                                    label="Enable Ratio"
                                />
                            </Grid>
                            <Grid container spacing={2} item xs={12} md={12} justify="space-around">
                                <Grid item xs={12} md={6}>
                                    <Card className={classes.block}>
                                        <Typography variant="h6" className={classes.title}>
                                            Outward Items
                                        </Typography>
                                        <ItemSelection
                                            label='Add Product'
                                            value={null} 
                                            optionLabel="name"
                                            options={products}
                                            style={{zIndex: 500, marginTop: 8, marginLeft: 0}}
                                            formClass={classes.formAutoClass}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    <Typography style={products.find(p => outward.map(i => i.product_id).includes(option.id)) ? {color: '#dddddd', fontStyle: 'italic'} : {}}>
                                                        {option.name}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                            selected={(value) => {
                                                if(!products.find(p => outward.map(i => i.product_id).includes(value.id))){
                                                    handleProductSelection('outward', value)
                                                }
                                            }}/>
                                        <List dense={true}>
                                            {
                                                outward.length ? outward.map((item, i) => 
                                                    <ListItem key={i}>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.smallRed}>
                                                                {i + 1}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={item.product.name}
                                                        />
                                                        {
                                                            template.ratio_enabled === 1 && (
                                                                <TextField
                                                                    required
                                                                    label="Ratio"
                                                                    value={item.ratio || 1}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    size="small"
                                                                    name="ratio"
                                                                    onChange={handleChangeItem('outward', item.product_id)}
                                                                    error={item.ratio < 1}
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            type: 'number',
                                                                            min: 1,
                                                                            max: 999999,
                                                                        }
                                                                    }}
                                                                    />
                                                            )
                                                        }
                                                        <ListItemSecondaryAction>
                                                            <IconButton edge="end" aria-label="delete" onClick={remove('outward', item.product_id)}>
                                                                <HighlightOffIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ) : (
                                                    <ListItem>
                                                        <ListItemText
                                                            secondary="No products Selected"
                                                        />
                                                    </ListItem>
                                                )
                                            }
                                        </List>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Card className={classes.block}>
                                        <Typography variant="h6" className={classes.title}>
                                            Inward Items
                                        </Typography>
                                        <ItemSelection
                                            label='Add Product'
                                            value={null}
                                            optionLabel="name"
                                            options={products}
                                            style={{zIndex: 500, marginTop: 8, marginLeft: 0}}
                                            formClass={classes.formAutoClass}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    <Typography style={products.find(p => inward.map(i => i.product_id).includes(option.id)) ? {color: '#dddddd', fontStyle: 'italic'} : {}}>
                                                        {option.name}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                            selected={(value) => {
                                                if(!products.find(p => inward.map(i => i.product_id).includes(value.id))){
                                                    handleProductSelection('inward', value)
                                                }
                                            }}/>
                                        <List dense={true}>
                                            {
                                                inward.map((item, i) => 
                                                    <ListItem key={i}>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.small}>
                                                                {i + 1}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={item.product.name}
                                                        />
                                                        {
                                                            template.ratio_enabled === 1 && (
                                                                <TextField
                                                                    required
                                                                    label="Ratio"
                                                                    value={item.ratio || 1}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    size="small"
                                                                    name="ratio"
                                                                    onChange={handleChangeItem('inward', item.product_id)}
                                                                    error={item.ratio < 1}
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            type: 'number',
                                                                            //min: 1,
                                                                            max: 999999,
                                                                        }
                                                                    }}
                                                                    />
                                                            )
                                                        }
                                                        <ListItemSecondaryAction>
                                                            <Tooltip arrow title="Remove Product">
                                                                <IconButton edge="end" aria-label="delete" onClick={remove('inward', item.product_id)}>
                                                                    <HighlightOffIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                             }
                                             { inward.length === 0 && (
                                                    <ListItem>
                                                        <ListItemText
                                                            secondary="No products Selected"
                                                        />
                                                    </ListItem>
                                                )
                                            }
                                        </List>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
				}
				title={id ? "Modify Template" : "Add Template"}
				open={open}
                closeButtonText='Cancel'
				close={onClose}
                confirmButtonText='save'
                handleConfirm={onSave}
			/>
        </div>
    )
}

AddTemplate.prototype = {
    show: PropTypes.bool.isRequired,
    id: PropTypes.string,
    close: PropTypes.func.isRequired,
};

export default AddTemplate
